import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, get, computed } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import { task } from 'ember-concurrency';

const { ARCHIVAL_STATE } = Constants;

@classic
export default class WeldingProcedureSpecificationsArchivedController extends Controller {
  @service('weldnote-data')
  data;

  @service
  userSession;

  @service
  intl;

  @alias('model.list')
  wps;

  @alias('model.count')
  wpsCount;

  page = 1;

  loadData = task(async (pageNumber = 1) => {
    let results = await this.data.listAllWeldingProcedureSpecifications(
      { page: pageNumber },
      {
        archivalState: ARCHIVAL_STATE.ARCHIVED,
      }
    );
    set(this, 'model', results);
  });

  @computed
  get gridColumns() {
    let { intl } = this;

    let wpsNumberLabel = intl.t('model.welding-procedure-specification.wpsNumber.label');
    let pqrNumberLabel = intl.t('model.pqr.model-plural');
    let weldTypeLabel = intl.t('wps-list.grid-columns.weld-type');
    let weldingProcessesLabel = intl.t('wps-list.grid-columns.welding-processes');
    let diameterLabel = intl.t('wps-list.grid-columns.diameter', {
      unit: this.userSession.distanceUnit,
    });
    let thicknessLabel = intl.t('wps-list.grid-columns.base-material-thickness', {
      unit: this.userSession.distanceUnit,
    });
    let baseMaterialsLabel = intl.t('wps-list.grid-columns.base-materials');
    let heatInputLabel = intl.t('wps-list.grid-columns.heat-input', {
      unit: this.userSession.heatDistanceUnit,
    });
    let preHeatLabel = intl.t('wps-list.grid-columns.pre-heat', {
      unit: this.userSession.temperatureUnit,
    });
    let pwhtLabel = intl.t('wps-list.grid-columns.post-weld-heat-treatment', {
      unit: this.userSession.temperatureUnit,
    });

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'wpsNumber',
        label: wpsNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/wps-link-archive',
        sortable: false,
      },
      {
        valuePath: 'pqrs',
        label: pqrNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/pqrs-link',
        sortable: false,
      },
      {
        valuePath: 'weldType',
        width: '100px',
        label: weldTypeLabel,
        cellComponent: 'weldnote-grid/weld-type',
        sortable: false,
      },
      {
        valuePath: 'weldingProcesses',
        label: weldingProcessesLabel,
        width: '120px',
        sortable: false,
        cellComponent: 'weldnote-grid/welding-processes',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterials',
        label: baseMaterialsLabel,
        cellComponent: 'weldnote-grid/base-material-for-list',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'thickness',
        label: thicknessLabel,
        align: 'right',
        cellComponent: 'weldnote-grid/base-material-thickness-for-list',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'diameter',
        label: diameterLabel,
        cellComponent: 'weldnote-grid/pqr-diameter',
        align: 'right',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'heatInput',
        label: heatInputLabel,
        align: 'right',
        cellComponent: 'weldnote-grid/pqr-heat-input',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'preHeat',
        label: preHeatLabel,
        align: 'right',
        sortable: false,
        cellComponent: 'weldnote-grid/pqr-preheat',
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'postWeldHeatTreatment',
        cellComponent: 'weldnote-grid/pqr-pwht',
        label: pwhtLabel,
        align: 'right',
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }
}
