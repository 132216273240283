import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';

export function thicknessValidation(rootProperty, fillProperty, capProperty, totalProperty) {
  return {
    validation(key, value, model) {
      if (!isEmpty(value)) {
        let session = get(model, 'userSession');
        if (!session.hasFeature('ge-requirements')) {
          return true;
        }
        let thicknessRoot = get(model, rootProperty);
        let thicknessFill = get(model, fillProperty);
        let thicknessCap = get(model, capProperty);
        let total = parseFloat(get(model, totalProperty));

        let sum = 0;
        if (!isEmpty(thicknessRoot)) {
          sum += parseFloat(thicknessRoot);
        }
        if (!isEmpty(thicknessFill)) {
          sum += parseFloat(thicknessFill);
        }
        if (!isEmpty(thicknessCap)) {
          sum += parseFloat(thicknessCap);
        }

        if (!isNaN(sum) && !isNaN(total)) {
          if (sum <= parseFloat(total) * 1.1) {
            return true;
          }
          return false;
        }
      }
      return true;
    },

    message(key, value, model) {
      let thicknessRoot = get(model, rootProperty);
      let thicknessFill = get(model, fillProperty);
      let thicknessCap = get(model, capProperty);
      let total = get(model, totalProperty);

      let sum = 0;
      if (!isEmpty(thicknessRoot)) {
        sum += parseFloat(thicknessRoot);
      }
      if (!isEmpty(thicknessFill)) {
        sum += parseFloat(thicknessFill);
      }
      if (!isEmpty(thicknessCap)) {
        sum += parseFloat(thicknessCap);
      }
      let unit = get(model, 'distanceUnit');
      if (isEmpty(unit)) {
        unit = get(model, 'userSession.distanceUnit');
      }
      return model.translate('model-validations.thickness-over-limit', {
        sum,
        total,
        unit,
      });
    },
  };
}
