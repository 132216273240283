import { A } from '@ember/array';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import WeldingPositionApprovalRange from 'weldnote/utils/approval-range/pqr/welding-position';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import Constants from 'weldnote/utils/constants';
import {
  filterMecanizations,
  isManualMecanization,
} from 'weldnote/utils/rules/process-mecanization';
import {
  isCompleteJointPenetrationButtWeld,
  isFilletWeld,
  isPartialJointPenetrationButtWeld,
} from 'weldnote/utils/rules/weld-type';
import {
  allGTAW,
  isFCAW,
  isFCAWS,
  isGMAW,
  isMCAW,
  isSMAW,
} from 'weldnote/utils/rules/welding-process';
import { fixDecimalsForUnitSystem } from 'weldnote/utils/unit-system/format';

const { PRODUCT_TYPE, WELD_TYPES, WELDING_DETAILS, UNIT_SYSTEM, PROCESS_MECANIZATION } = Constants;

export default class AwsD11ApprovalRanges {
  constructor(
    pqr,
    {
      allWeldTypes,
      allBaseMaterials,
      allWeldingDetails,
      allProcessMecanizations,
      allTypeCurrentPolarity,
      allWeldingPositions,
      allTransferModes,
      unitSystem = UNIT_SYSTEM.METRIC,
    }
  ) {
    this.pqr = pqr;
    let industryCodeId = get(this.standard, 'code.id');

    this.allWeldTypes = allWeldTypes.filter((weldType) => {
      return get(weldType, 'industryCode.id') === industryCodeId;
    });
    this._baseMaterials = allBaseMaterials;
    this.allWeldingDetails = allWeldingDetails;
    this.allProcessMecanizations = allProcessMecanizations;
    this.allTypeCurrentPolarity = allTypeCurrentPolarity;
    this._weldingPositions = allWeldingPositions;
    this._transferModes = allTransferModes;
    this.unitSystem = pqr.unitSystem || unitSystem;
    if (unitSystem === null) {
      throw new Error('Unit System is required for AWS D1.1 Approval Ranges');
    }
  }

  get weldingProcessRoot() {
    return this.pqr.get('weldingProcessRootTestPiece');
  }

  get weldingProcessFill() {
    return this.pqr.get('weldingProcessFillTestPiece');
  }

  get weldingProcessCap() {
    return this.pqr.get('weldingProcessCapTestPiece');
  }

  get allWeldingPositions() {
    return this._weldingPositions.filter(
      (position) => get(position, 'industryCode.id') === this.industryCodeId
    );
  }

  get industryCodeId() {
    return get(this.standard, 'code.id');
  }

  get allBaseMaterials() {
    return this._baseMaterials.filter(
      (baseMaterial) => get(baseMaterial, 'code.id') === this.industryCodeId
    );
  }

  weldingProcess(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    return [testPiece];
  }

  processMecanization(/* testPiece */) {
    return [];
  }

  _filterCurrentWeldTypes(types) {
    let result = [];
    types.forEach((t) => {
      let weldType = this.allWeldTypes.findBy('weldType', t);
      if (weldType) {
        result.pushObject(weldType);
      }
    });
    return result;
  }

  weldType(testPiece) {
    if (isEmpty(testPiece)) {
      return [];
    }
    let results = [];
    if (isCompleteJointPenetrationButtWeld(testPiece) && !isEmpty(this.productTypeTestPiece)) {
      return this._filterCurrentWeldTypes([
        WELD_TYPES.FILLET_WELD,
        WELD_TYPES.PARTIAL_JOINT_PENETRATION_BUTT_WELD,
        WELD_TYPES.COMPLETE_JOINT_PENETRATION_BUTT_WELD,
      ]);
    } else if (isPartialJointPenetrationButtWeld(testPiece)) {
      return this._filterCurrentWeldTypes([
        WELD_TYPES.FILLET_WELD,
        WELD_TYPES.PARTIAL_JOINT_PENETRATION_BUTT_WELD,
      ]);
    }
    if (isEmpty(results)) {
      results.pushObject(testPiece);
    }
    return results;
  }

  weldingPosition(testPiece, direction) {
    if (isEmpty(testPiece)) {
      return [];
    }

    let calculator = new WeldingPositionApprovalRange(this.allWeldingPositions, {
      standard: this.standard,
      weldType: this.weldTypeCode,
      impactRequirements: this.impactRequirements,
      productType: this.productTypeTestPiece,
    });

    return calculator.calculateWeldingPositions(testPiece, direction);
  }

  transferMode(transferMode) {
    if (isEmpty(transferMode)) {
      return [];
    }
    return [transferMode];
  }

  transferModeFill(transferMode) {
    if (isEmpty(transferMode)) {
      return [];
    }
    return [transferMode];
  }

  transferModeCap(transferMode) {
    if (isEmpty(transferMode)) {
      return [];
    }
    return [transferMode];
  }

  get _emptyRange() {
    return {
      min: null,
      max: null,
    };
  }

  get standard() {
    return this.pqr.get('standard');
  }

  get isSingleLayer() {
    return !isEmpty(this.pqr.get('weldingDetailsTestPiece').findBy('shortDesignation', 'sl'));
  }

  get isMetricSystem() {
    return this.unitSystem === UNIT_SYSTEM.METRIC;
  }

  get isImperialSystem() {
    return this.unitSystem === UNIT_SYSTEM.IMPERIAL;
  }

  get productTypeTestPiece() {
    return this.pqr.get('productTypeTestPiece');
  }

  get isPipe() {
    let { productTypeTestPiece } = this;
    if (!isEmpty(productTypeTestPiece)) {
      return productTypeTestPiece === PRODUCT_TYPE.PIPE;
    }
    return false;
  }

  get isPlate() {
    let { productTypeTestPiece } = this;
    if (!isEmpty(productTypeTestPiece)) {
      return productTypeTestPiece === PRODUCT_TYPE.PLATE;
    }
    return false;
  }

  get weldTypeTestPiece() {
    return this.pqr.get('weldTypeTestPiece');
  }

  get weldTypeCode() {
    let { weldTypeTestPiece: weldType } = this;
    if (isEmpty(weldType)) {
      return '';
    }
    return get(weldType, 'weldType');
  }

  get isFilletWeld() {
    return isFilletWeld(this.weldTypeTestPiece);
  }

  get isPartialJointPenetrationButtWeld() {
    return isPartialJointPenetrationButtWeld(this.weldTypeTestPiece);
  }

  get isCompleteJointPenetrationButtWeld() {
    return isCompleteJointPenetrationButtWeld(this.weldTypeTestPiece);
  }

  get impactRequirements() {
    let { impactTemperature, impactRequired } = this;
    return !isEmpty(impactRequired) && impactRequired && !isNaN(parseFloat(impactTemperature));
  }

  get impactTemperature() {
    return this.pqr.get('impactTestTemperature');
  }

  get impactTestReport() {
    return this.pqr.get('impactTestReport');
  }

  get impactRequired() {
    return this.pqr.get('impactTestRequired');
  }

  get productType() {
    return this.pqr.get('productTypeTestPiece');
  }

  get diameterValue() {
    return this.pqr.get('diameterTestPiece');
  }

  weldedThickness(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._calculateWeldingThicknessRange(testPiece);
  }

  _calculateWeldingThicknessRange(testPiece) {
    let thickness = parseFloat(testPiece);
    let { diameterValue: diameter } = this;

    if (isNaN(thickness)) {
      return this._emptyRange;
    }

    let result = {};

    let specialCase = false;
    if (this.isMetricSystem) {
      if (thickness === 5.5 && diameter === 60.3) {
        result.min = 3;
        result.max = 20;
        specialCase = true;
      }
      if (thickness === 5.5 && diameter === 88.9) {
        result.min = 3;
        result.max = 20;
        specialCase = true;
      }
      if (thickness === 14.3 && diameter === 168.3) {
        result.min = 5;
        result.max = null;
        specialCase = true;
      }
      if (thickness === 12.7 && diameter === 219.1) {
        result.min = 5;
        result.max = null;
        specialCase = true;
      }
    } else if (this.isImperialSystem) {
      if (thickness === 0.218 && diameter === 2) {
        result.min = 0.125;
        result.max = 0.75;
        specialCase = true;
      }
      if (thickness === 0.216 && diameter === 3) {
        result.min = 0.125;
        result.max = 0.75;
        specialCase = true;
      }
      if (thickness === 0.562 && diameter === 6) {
        result.min = 0.1875;
        result.max = null;
        specialCase = true;
      }
      if (thickness === 0.5 && diameter === 8) {
        result.min = 0.1875;
        result.max = null;
        specialCase = true;
      }
    }
    if (!specialCase) {
      if (this.isCompleteJointPenetrationButtWeld && this.isPlate) {
        if (this.isMetricSystem) {
          if (thickness >= 3 && thickness <= 10) {
            result.min = 3;
            result.max = thickness * 2;
          } else if (thickness > 10 && thickness < 25) {
            result.min = 3;
            result.max = thickness * 2;
          } else if (thickness >= 25) {
            result.min = 3;
            result.max = null;
          }
        } else if (this.isImperialSystem) {
          if (thickness >= 0.125 && thickness <= 0.375) {
            result.min = 0.125;
            result.max = thickness * 2;
          } else if (thickness > 0.375 && thickness < 1) {
            result.min = 0.125;
            result.max = thickness * 2;
          } else if (thickness >= 1) {
            result.min = 0.125;
            result.max = null;
          }
        }
      } else if (this.isCompleteJointPenetrationButtWeld && this.isPipe) {
        if (this.isMetricSystem) {
          if (thickness >= 3 && thickness <= 10) {
            result.min = 3;
            result.max = thickness * 2;
          } else if (thickness > 10 && thickness < 20) {
            result.min = thickness * 0.5;
            result.max = thickness * 2;
          } else if (thickness >= 20) {
            result.min = 10;
            result.max = null;
          }
        } else if (this.isImperialSystem) {
          if (thickness >= 0.125 && thickness <= 0.375) {
            result.min = 0.125;
            result.max = thickness * 2;
          } else if (thickness > 0.375 && thickness < 0.75) {
            result.min = thickness * 0.5;
            result.max = thickness * 2;
          } else if (thickness >= 0.75) {
            result.min = 0.375;
            result.max = null;
          }
        }
      } else if (this.isPartialJointPenetrationButtWeld) {
        if (this.isMetricSystem) {
          if (thickness >= 3 && thickness <= 10) {
            result.min = 3;
            result.max = thickness * 2;
          } else if (thickness > 10 && thickness < 25) {
            result.min = 3;
            result.max = null;
          } else if (thickness >= 25) {
            result.min = 3;
            result.max = null;
          }
        } else if (this.isImperialSystem) {
          if (thickness >= 0.125 && thickness <= 0.375) {
            result.min = 0.125;
            result.max = thickness * 2;
          } else if (thickness > 0.375 && thickness < 1) {
            result.min = 0.125;
            result.max = null;
          } else if (thickness >= 1) {
            result.min = 0.125;
            result.max = null;
          }
        }
      } else if (this.isFilletWeld) {
        if (this.isSingleLayer) {
          if (this.isMetricSystem) {
            result.min = 3;
            result.max = null;
          } else if (this.isImperialSystem) {
            result.min = 0.125;
            result.max = null;
          }
        } else {
          if (this.isMetricSystem) {
            result.min = 3;
            result.max = null;
          } else if (this.isImperialSystem) {
            result.min = 0.125;
            result.max = null;
          }
        }
      }
    }
    return result;
  }

  thicknessProcess(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    return this._calculateWeldingThicknessRange(testPiece);
  }

  weldingDetails(weldingDetails) {
    if (isEmpty(weldingDetails)) {
      return [];
    }

    let resultDetailNames = [];
    let result = [];
    weldingDetails.forEach((detail) => {
      let designation = detail.shortDesignation;
      if (designation === WELDING_DETAILS.SINGLE_SIDE_NO_BACKING) {
        [
          WELDING_DETAILS.SINGLE_SIDE_NO_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_GAS_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
        ].forEach((currentDetail) => {
          resultDetailNames.pushObject(currentDetail);
        });
      } else if (designation === WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING) {
        [
          WELDING_DETAILS.SINGLE_SIDE_FLUX_BACKING,
          WELDING_DETAILS.SINGLE_SIDE_MATERIAL_BACKING,
          WELDING_DETAILS.BOTH_SIDES,
        ].forEach((currentDetail) => {
          resultDetailNames.pushObject(currentDetail);
        });
      } else {
        resultDetailNames.pushObject(designation);
      }
    });

    resultDetailNames.uniq().forEach((name) => {
      let position = this.allWeldingDetails.findBy('shortDesignation', name);
      if (position) {
        result.pushObject(position);
      }
    });
    return result;
  }

  _calculateProcessMecanization(processMecanization, weldingProcess) {
    if (isEmpty(processMecanization)) {
      return [];
    }
    if (isSMAW(weldingProcess) && isManualMecanization(processMecanization)) {
      return filterMecanizations(this.allProcessMecanizations, [PROCESS_MECANIZATION.MANUAL]);
    } else if (
      (isGMAW(weldingProcess) ||
        isFCAW(weldingProcess) ||
        isMCAW(weldingProcess) ||
        isFCAWS(weldingProcess)) &&
      !isManualMecanization(processMecanization)
    ) {
      return filterMecanizations(this.allProcessMecanizations, [
        PROCESS_MECANIZATION.SEMI_AUTOMATIC,
        PROCESS_MECANIZATION.MECHANIZED,
        PROCESS_MECANIZATION.AUTOMATIC,
      ]);
    } else {
      return [processMecanization];
    }
  }

  processMecanization(processMecanization, weldingProcess) {
    return this._calculateProcessMecanization(processMecanization, weldingProcess);
  }

  typeCurrentAndPolarity(typeCurrent) {
    if (isEmpty(typeCurrent)) {
      return [];
    }
    return [typeCurrent];
  }

  preHeat(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }

    let preHeat = parseFloat(testPiece);
    if (isNaN(preHeat)) {
      return this._emptyRange;
    }

    let result = {};
    let { weldingProcessRoot: root, weldingProcessFill: fill, weldingProcessCap: cap } = this;
    if (allGTAW(root, fill, cap)) {
      result.min = preHeat - 55;
    } else {
      result.min = preHeat - 15;
    }
    if (this.impactRequirements) {
      result.max = preHeat + 56;
    }

    return result;
  }

  get interpassMinimumApprovalRange() {
    return this.pqr.get('interpassTemperatureMinimumApprovalRange');
  }

  interpass(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let interpassValue = parseFloat(testPiece);
    if (isNaN(interpassValue)) {
      return this._emptyRange;
    }
    let result = {};
    if (this.impactRequirements) {
      result.max = interpassValue + 56;
    }

    if (this.interpassMinimumApprovalRange > 0 && result.min === 0) {
      result.min = this.interpassMinimumApprovalRange;
    }

    return result;
  }

  postHeat(postHeat) {
    return this._emptyRange;
  }

  pwhtTemperature(pwht) {
    if (isEmpty(pwht)) {
      return this._emptyRange;
    }
    let postWeldHeatTreament = parseFloat(pwht);
    if (isNaN(postWeldHeatTreament)) {
      return this._emptyRange;
    }
    return {
      min: postWeldHeatTreament,
      max: postWeldHeatTreament,
    };
  }

  pwhtTime(time) {
    if (isEmpty(time)) {
      return this._emptyRange;
    }

    let timeFloat = parseFloat(time);
    if (isNaN(timeFloat)) {
      return this._emptyRange;
    }
    return {
      min: timeFloat,
      max: timeFloat * 1.25,
    };
  }

  _filterGroups(groups) {
    let result = A();
    groups.forEach((group) => {
      let groupModel = this.allBaseMaterials.findBy('groupName', group);
      if (groupModel) {
        result.push(groupModel);
      }
    });
    return result;
  }

  baseMaterial(baseMaterial1, baseMaterial2) {
    if (isEmpty(baseMaterial1) || isEmpty(baseMaterial2)) {
      return {
        material1: [],
        material2: [],
      };
    }

    let result = {
      material1: [],
      material2: [],
    };

    let group = baseMaterial1.get('materialGroup.groupName');
    let group2 = baseMaterial2.get('materialGroup.groupName');

    if (group === 'I' && group2 === 'I') {
      result.material1 = this._filterGroups(['I']);
      result.material2 = this._filterGroups(['I']);
    } else if (group === 'II' && group2 === 'II') {
      if (this.impactRequirements) {
        result.material1 = this._filterGroups(['II']);
        result.material2 = this._filterGroups(['II']);
      } else {
        result.material1 = this._filterGroups(['I', 'II']);
        result.material2 = this._filterGroups(['I', 'II']);
      }
    } else if ((group === 'III' || group === 'Table 4.9') && group2 === 'I') {
      result.material1 = [];
      result.material2 = this._filterGroups(['I']);
    } else if ((group === 'I' || group === 'Table 4.9') && group2 === 'II') {
      if (this.impactRequirements) {
        result.material1 = [];
        result.material2 = this._filterGroups(['II']);
      } else {
        result.material1 = [];
        result.material2 = this._filterGroups(['I', 'II']);
      }
    } else {
      result.material1 = [];
      result.material2 = [];
    }
    return result;
  }

  _thicknessAWSPlateCompleteJointButtWeldMetricSystem(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness >= 3 && thickness <= 10) {
      result.min = 3;
      result.max = thickness * 2;
    } else if (thickness > 10 && thickness < 25) {
      result.min = 3;
      result.max = thickness * 2;
    } else if (thickness >= 25) {
      result.min = 3;
      result.max = null;
    }
    return result;
  }

  _thicknessAWSPlateCompleteJointButtWeldImperialSystem(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness >= 0.125 && thickness <= 0.375) {
      result.min = 0.125;
      result.max = thickness * 2;
    } else if (thickness > 0.375 && thickness < 1) {
      result.min = 0.125;
      result.max = thickness * 2;
    } else if (thickness >= 1) {
      result.min = 0.125;
      result.max = null;
    }
    return result;
  }

  _thicknessAWSPipeCompleteJointButtWeldMetricSystem(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness >= 3 && thickness <= 10) {
      result.min = 3;
      result.max = thickness * 2;
    } else if (thickness > 10 && thickness < 20) {
      result.min = thickness * 0.5;
      result.max = thickness * 2;
    } else if (thickness >= 20) {
      result.min = 10;
      result.max = null;
    }
    return result;
  }

  _thicknessAWSPipeCompleteJointButtWeldImperialSystem(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness >= 0.125 && thickness <= 0.375) {
      result.min = 0.125;
      result.max = thickness * 2;
    } else if (thickness > 0.375 && thickness < 0.75) {
      result.min = thickness * 0.5;
      result.max = thickness * 2;
    } else if (thickness >= 0.75) {
      result.min = 0.375;
      result.max = null;
    }
    return result;
  }

  _thicknessAWSPartialPenetrationButtWeldMetricSystem(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness >= 3 && thickness <= 10) {
      result.min = 3;
      result.max = thickness * 2;
    } else if (thickness > 10 && thickness < 25) {
      result.min = 3;
      result.max = null;
    } else if (thickness >= 25) {
      result.min = 3;
      result.max = null;
    }
    return result;
  }

  _thicknessAWSPartialPenetrationButtWeldImperialSystem(thickness) {
    let result = this._emptyRange;
    if (isNaN(thickness)) {
      return this._emptyRange;
    }
    if (thickness >= 0.125 && thickness <= 0.375) {
      result.min = 0.125;
      result.max = thickness * 2;
    } else if (thickness > 0.375 && thickness < 1) {
      result.min = 0.125;
      result.max = null;
    } else if (thickness >= 1) {
      result.min = 0.125;
      result.max = null;
    }
    return result;
  }

  _awsStandardSpecialCase(thickness, diameter, unitSystem = UNIT_SYSTEM.METRIC) {
    let result = {
      specialCase: false,
      min: null,
      max: null,
    };
    if (isNaN(thickness)) {
      return this._emptyRange;
    }

    if (unitSystem === UNIT_SYSTEM.METRIC) {
      if (thickness === 5.5 && diameter === 60.3) {
        result.min = 3;
        result.max = 20;
        result.specialCase = true;
      }
      if (thickness === 5.5 && diameter === 88.9) {
        result.min = 3;
        result.max = 20;
        result.specialCase = true;
      }
      if (thickness === 14.3 && diameter === 168.3) {
        result.min = 5;
        result.max = null;
        result.specialCase = true;
      }
      if (thickness === 12.7 && diameter === 219.1) {
        result.min = 5;
        result.max = null;
        result.specialCase = true;
      }
    } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (thickness === 0.218 && diameter === 2) {
        result.min = 0.125;
        result.max = 0.75;
        result.specialCase = true;
      }
      if (thickness === 0.216 && diameter === 3) {
        result.min = 0.125;
        result.max = 0.75;
        result.specialCase = true;
      }
      if (thickness === 0.562 && diameter === 6) {
        result.min = 0.1875;
        result.max = null;
        result.specialCase = true;
      }
      if (thickness === 0.5 && diameter === 8) {
        result.min = 0.1875;
        result.max = null;
        result.specialCase = true;
      }
    }

    return result;
  }

  get baseMaterial1Diameter() {
    return this.pqr.get('baseMaterial1Diameter');
  }

  get baseMaterial2Diameter() {
    return this.pqr.get('baseMaterial2Diameter');
  }

  baseMaterialThickness(thickness1, thickness2) {
    let resultThickness1 = {
      min: 0,
      max: null,
    };
    let resultThickness2 = {
      min: 0,
      max: null,
    };

    if (isEmpty(thickness1) && isEmpty(thickness2)) {
      return {
        material1: {
          min: null,
          max: null,
        },

        material2: {
          min: null,
          max: null,
        },
      };
    }

    if (this.isCompleteJointPenetrationButtWeld && this.isPlate) {
      if (this.isMetricSystem) {
        resultThickness1 = this._thicknessAWSPlateCompleteJointButtWeldMetricSystem(thickness1);
        resultThickness2 = this._thicknessAWSPlateCompleteJointButtWeldMetricSystem(thickness2);
      } else if (this.isImperialSystem) {
        resultThickness1 = this._thicknessAWSPlateCompleteJointButtWeldImperialSystem(thickness1);
        resultThickness2 = this._thicknessAWSPlateCompleteJointButtWeldImperialSystem(thickness2);
      }
    } else if (this.isCompleteJointPenetrationButtWeld && this.isPipe) {
      if (this.isMetricSystem) {
        resultThickness1 = this._thicknessAWSPipeCompleteJointButtWeldMetricSystem(thickness1);
        resultThickness2 = this._thicknessAWSPipeCompleteJointButtWeldMetricSystem(thickness2);
      } else if (this.isImperialSystem) {
        resultThickness1 = this._thicknessAWSPipeCompleteJointButtWeldImperialSystem(thickness1);
        resultThickness2 = this._thicknessAWSPipeCompleteJointButtWeldImperialSystem(thickness2);
      }
    } else if (this.isPartialJointPenetrationButtWeld) {
      if (this.isMetricSystem) {
        resultThickness1 = this._thicknessAWSPartialPenetrationButtWeldMetricSystem(thickness1);
        resultThickness2 = this._thicknessAWSPartialPenetrationButtWeldMetricSystem(thickness2);
      } else if (this.isImperialSystem) {
        resultThickness1 = this._thicknessAWSPartialPenetrationButtWeldImperialSystem(thickness1);
        resultThickness2 = this._thicknessAWSPartialPenetrationButtWeldImperialSystem(thickness2);
      }
    } else if (this.isFilletWeld) {
      if (this.isMetricSystem) {
        resultThickness1.min = 3;
        resultThickness1.max = null;
        resultThickness2.min = 3;
        resultThickness2.max = null;
      } else if (this.isImperialSystem) {
        resultThickness1.min = 0.125;
        resultThickness1.max = null;
        resultThickness2.min = 0.125;
        resultThickness2.max = null;
      }
    }

    let specialCaseThickness1 = this._awsStandardSpecialCase(
      thickness1,
      this.baseMaterial1Diameter,
      this.unitSystem
    );
    let specialCaseThickness2 = this._awsStandardSpecialCase(
      thickness2,
      this.baseMaterial2Diameter,
      this.unitSystem
    );

    if (specialCaseThickness1.specialCase) {
      resultThickness1.min = specialCaseThickness1.min;
      resultThickness1.max = specialCaseThickness1.max;
    }

    if (specialCaseThickness2.specialCase) {
      resultThickness2.min = specialCaseThickness2.min;
      resultThickness2.max = specialCaseThickness2.max;
    }

    return {
      material1: fixDecimalsForUnitSystem(resultThickness1, this.unitSystem),
      material2: fixDecimalsForUnitSystem(resultThickness2, this.unitSystem),
    };
  }

  branchAngle(/* testPiece */) {
    return this._emptyRange;
  }

  fillerMaterialRootCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }

    if (isFCAW(this.weldingProcessRoot)) {
      return [designation];
    }

    return [];
  }

  fillerMaterialFillCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }

    if (isFCAW(this.weldingProcessFill)) {
      return [designation];
    }

    return [];
  }

  fillerMaterialCapCommercialDesignation(designation) {
    if (isEmpty(designation)) {
      return [];
    }

    if (isFCAW(this.weldingProcessCap)) {
      return [designation];
    }

    return [];
  }

  throatThickness(thickness) {
    if (isEmpty(thickness)) {
      return this._emptyRange;
    }
    return {
      min: 0,
      max: null,
    };
  }

  get totalThickness() {
    return this.pqr.get('thicknessTotalTestPiece');
  }

  diameter(testPiece) {
    if (isEmpty(testPiece)) {
      return this._emptyRange;
    }
    let result = {
      min: null,
      max: null,
    };

    let thickness = this.totalThickness;
    let specialCase = false;
    let diameter = parseFloat(testPiece);
    if (isNaN(diameter)) {
      return this._emptyRange;
    }

    if (this.isMetricSystem) {
      if (thickness === 5.5 && diameter === 60.3) {
        result.min = 20;
        result.max = 100;
        specialCase = true;
      }
      if (thickness === 5.5 && diameter === 88.9) {
        result.min = 20;
        result.max = 100;
        specialCase = true;
      }
      if (thickness === 14.3 && diameter === 168.3) {
        result.min = 100;
        result.max = null;
        specialCase = true;
      }
      if (thickness === 12.7 && diameter === 219.1) {
        result.min = 100;
        result.max = null;
        specialCase = true;
      }
    } else if (this.isImperialSystem) {
      if (thickness === 0.218 && diameter === 2) {
        result.min = 0.75;
        result.max = 4;
        specialCase = true;
      }
      if (thickness === 0.216 && diameter === 3) {
        result.min = 0.75;
        result.max = 4;
        specialCase = true;
      }
      if (thickness === 0.562 && diameter === 6) {
        result.min = 4;
        result.max = null;
        specialCase = true;
      }
      if (thickness === 0.5 && diameter === 8) {
        result.min = 4;
        result.max = null;
        specialCase = true;
      }
    }
    if (!specialCase) {
      if (this.isCompleteJointPenetrationButtWeld && this.isPipe) {
        if (this.isMetricSystem) {
          if (diameter < 600) {
            result.min = diameter;
            result.max = null;
          } else {
            if (thickness >= 3 && thickness <= 10) {
              result.min = diameter;
              result.max = null;
            } else {
              result.min = 600;
              result.max = null;
            }
          }
        } else if (this.isImperialSystem) {
          if (diameter < 24) {
            result.min = diameter;
            result.max = null;
          } else {
            if (thickness >= 0.125 && thickness <= 0.375) {
              result.min = diameter;
              result.max = null;
            } else {
              result.min = 24;
              result.max = null;
            }
          }
        }
      }
    }
    return result;
  }

  _calculatePjpButtWeldBaseMaterialThickness() {
    let result = {
      min: null,
      max: null,
    };

    if (this.isCompleteJointPenetrationButtWeld && this.isPlate) {
      if (this.isMetricSystem) {
        result.min = 3;
        result.max = null;
      } else if (this.isImperialSystem) {
        result.min = 0.125;
        result.max = null;
      }
    } else if (this.isCompleteJointPenetrationButtWeld && this.isPipe) {
      if (this.isMetricSystem) {
        result.min = 3;
        result.max = null;
      } else if (this.isImperialSystem) {
        result.min = 0.125;
        result.max = null;
      }
    }

    return result;
  }

  pjpButtWeldBaseMaterial1Thickness() {
    return this._calculatePjpButtWeldBaseMaterialThickness();
  }

  pjpButtWeldBaseMaterial2Thickness() {
    return this._calculatePjpButtWeldBaseMaterialThickness();
  }

  _calculateBaseMaterialFilletWeldThickness() {
    let result = {
      min: null,
      max: null,
    };

    if (this.isCompleteJointPenetrationButtWeld && this.isPlate) {
      if (this.isMetricSystem) {
        result.min = 3;
        result.max = null;
      } else if (this.isImperialSystem) {
        result.min = 0.125;
        result.max = null;
      }
    } else if (this.isCompleteJointPenetrationButtWeld && this.isPipe) {
      if (this.isMetricSystem) {
        result.min = 3;
        result.max = null;
      } else if (this.isImperialSystem) {
        result.min = 0.125;
        result.max = null;
      }
    } else if (this.isPartialJointPenetrationButtWeld) {
      if (this.isMetricSystem) {
        result.min = 3;
        result.max = null;
      } else if (this.isImperialSystem) {
        result.min = 0.125;
        result.max = null;
      }
    }
    return result;
  }

  baseMaterial1FilletWeldThickness(thickness) {
    return this._calculateBaseMaterialFilletWeldThickness();
  }

  baseMaterial2FilletWeldThickness(thickness) {
    return this._calculateBaseMaterialFilletWeldThickness();
  }

  shielding(weldingProcessCode) {
    return calculateShielding(weldingProcessCode);
  }

  productType(testPiece) {
    let result = {
      productType: [],
      restrictions: [],
    };

    if (testPiece === PRODUCT_TYPE.PIPE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
    } else if (testPiece === PRODUCT_TYPE.PLATE) {
      result.productType = [PRODUCT_TYPE.PIPE, PRODUCT_TYPE.PLATE];
      if (unitSystem === UNIT_SYSTEM.METRIC) {
        result.restrictions.pushObject({
          productType: PRODUCT_TYPE.PIPE,
          diameter: {
            value: 600,
            unitSystem: UNIT_SYSTEM.METRIC,
          },
        });
      } else if (unitSystem === UNIT_SYSTEM.IMPERIAL) {
        result.restrictions.pushObject({
          productType: PRODUCT_TYPE.PIPE,
          diameter: {
            value: 24,
            unitSystem: UNIT_SYSTEM.IMPERIAL,
          },
        });
      }
    }
    return result;
  }
}
