import { action, computed, get, set } from '@ember/object';
import { alias, empty, equal, not } from '@ember/object/computed';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import classic from 'ember-classic-decorator';
import { task, timeout } from 'ember-concurrency';
import Env from 'weldnote/config/environment';
import ListController from 'weldnote/controllers/_base/list-js';
import filterFromCollectionByKey from 'weldnote/macros/find-from-collection-by-key';
import Constants from 'weldnote/utils/constants';
import { convertDecimals } from 'weldnote/utils/decimal-utils';
import PQRRules from 'weldnote/utils/pqr-rules';
import { isFraction, toNumber } from 'weldnote/utils/unit-system/units';

const {
  HTTP: { DEBOUNCE },
  WELD_TYPES,
  UNIT_SYSTEM,
} = Constants;

const { PQR } = PQRRules;

@classic
export default class PqrsController extends ListController {
  @service('weldnote-data')
  data;

  @service('weldcloud-notes-data')
  notesData;

  @service
  unitSystem;

  @service('instanceTracker')
  tracker;

  @service
  scroller;

  @service
  store;

  @service
  intl;

  @service
  userSession;

  @service
  ajax;

  @service
  help;

  @service
  analytics;

  @service
  router;

  modelName = 'pqr';

  @tracked
  totalCount = 0;

  @alias('data.pageSize')
  pageSize;

  @tracked
  dissmilarMaterials = false;

  @tracked
  baseMaterialThickness = null;

  @tracked
  baseMaterial1 = null;

  @tracked
  baseMaterial2 = null;

  @tracked
  baseMaterial = null;

  @tracked
  searchBaseMaterialByGroup = true;

  @tracked
  searchBaseMaterial1ByGroup = true;

  @tracked
  searchBaseMaterial2ByGroup = true;

  @alias('data.productTypeOptions')
  productTypeOptions;

  @alias('data.standardOptions')
  standardOptions;

  @alias('data.weldingDetailOptions')
  weldingDetailOptions;

  @alias('data.weldingProcessOptions')
  weldingProcessesAll;

  @alias('data.weldingPositionOptions')
  weldingPositionsAll;

  @alias('data.weldTypeOptions')
  weldTypeOptionsAll;

  @alias('selectedStandard.code')
  industryCode;

  @alias('data.examiningBodyOptions')
  examiningBodyOptions;

  @alias('data.specialRequirementOptions')
  specialRequirementOptions;

  @alias('data.companyOptions')
  companyOptions;

  @alias('selectedStandard.code')
  currentIndustryCode;

  get currentUnitSystem() {
    if (!isEmpty(this.selectedUnitSystem)) {
      return this.selectedUnitSystem.key;
    }
    return '';
  }

  get temperatureUnit() {
    if (!isEmpty(this.currentUnitSystem)) {
      return this.unitSystem.getTemperatureUnit(this.currentUnitSystem);
    }
    return this.userSession.temperatureUnit;
  }


  get distanceUnit() {
    if (!isEmpty(this.currentUnitSystem)) {
      return this.unitSystem.getDistanceUnit(this.currentUnitSystem);
    }
    return this.userSession.distanceUnit;
  }

  get flowUnit() {
    if (!isEmpty(this.currentUnitSystem)) {
      return this.unitSystem.getFlowUnit(this.currentUnitSystem);
    }
    return this.userSession.flowUnit;
  }

  get heatDistanceUnit() {
    if (!isEmpty(this.currentUnitSystem)) {
      return this.unitSystem.getHeatDistanceUnit(this.currentUnitSystem);
    }
    return this.userSession.heatDistanceUnit;
  }

  @alias('userSession.decimalSeparator')
  decimalSeparator;

  @tracked
  selectedStandard = null;

  @tracked
  selectedFillerMaterialRoot = null;

  @tracked
  selectedFillerMaterialFill = null;

  @tracked
  selectedFillerMaterialCap = null;

  @tracked
  selectedUnitSystem = null;

  @tracked
  showAdvancedParameters = false;

  @tracked
  showSearchParameters = false;

  @empty('selectedFillerMaterialRoot.id')
  fillerMaterialRootGroupDisabled;

  @empty('selectedFillerMaterialFill.id')
  fillerMaterialFillGroupDisabled;

  @empty('selectedFillerMaterialCap.id')
  fillerMaterialCapGroupDisabled;

  @empty('selectedStandard')
  fieldsDisabled;

  @not('fieldsDisabled')
  isStandardSelected;

  @equal('selectedWeldType.weldType', WELD_TYPES.OVERLAY_WELD)
  isOverlayWeld;

  @not('isOverlayWeld')
  showDissimilarMaterials;

  @alias('data.overlayTypeOptions')
  overlayTypeOptions;

  @filterFromCollectionByKey('weldTypeOptionsAll', 'industryCode.id', 'currentIndustryCode.id')
  weldTypeOptions;

  @filterFromCollectionByKey('weldingProcessesAll', 'industryCode.id', 'currentIndustryCode.id')
  weldingProcessOptions;

  @filterFromCollectionByKey('weldingPositionsAll', 'industryCode.id', 'currentIndustryCode.id')
  weldingPositionOptions;

  getGridColumnLabel(label, options = {}) {
    return this.intl.t(`pqr-list.grid-columns.${label}`, options);
  }

  @computed
  get gridColumns() {
    let certificateNameLabel = this.getGridColumnLabel('certificate-name');
    let weldTypeLabel = this.getGridColumnLabel('weld-type');
    let weldingProcessesLabel = this.getGridColumnLabel('welding-processes');
    let diameterLabel = this.getGridColumnLabel('diameter', { unit: this.distanceUnit });
    let baseMaterialThicknessLabel = this.getGridColumnLabel('base-material-thickness', {
      unit: this.distanceUnit,
    });
    let baseMaterialsLabel = this.getGridColumnLabel('base-materials');
    let heatInputLabel = this.getGridColumnLabel('heat-input', {
      unit: this.heatDistanceUnit,
    });
    let postWeldHeatTreamentLabel = this.getGridColumnLabel('post-weld-heat-treatment', {
      unit: this.temperatureUnit,
    });
    let preHeatLabel = this.getGridColumnLabel('pre-heat', { unit: this.temperatureUnit });

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'certificateNumber',
        label: certificateNameLabel,
        width: '300px',
        cellComponent: 'weldnote-grid/pqr-link',
        sortable: false,
      },
      {
        valuePath: 'weldType',
        label: weldTypeLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/weld-type',
        breakpoints: ['mobile', 'tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'weldingProcesses',
        label: weldingProcessesLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/welding-processes',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterials',
        label: baseMaterialsLabel,
        cellComponent: 'weldnote-grid/base-material-for-list',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterialThickness',
        label: baseMaterialThicknessLabel,
        align: 'right',
        cellComponent: 'weldnote-grid/base-material-thickness-for-list',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'diameter',
        align: 'right',
        label: diameterLabel,
        cellComponent: 'weldnote-grid/pqr-diameter',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'heatInput',
        align: 'right',
        label: heatInputLabel,
        cellComponent: 'weldnote-grid/pqr-heat-input',
        sortable: false, 
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'preHeat',
        align: 'right',
        label: preHeatLabel,
        cellComponent: 'weldnote-grid/pqr-preheat',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'postWeldHeatTreament',
        align: 'right',
        label: postWeldHeatTreamentLabel,
        cellComponent: 'weldnote-grid/pqr-pwht',
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }

  loadData = task(
    {
      restartable: true,
    },
    async (otherOptions = {}) => {
      let options = {};

      if (otherOptions.userSearch) {
        options.userSearch = true;
      }

      let { dir = 'asc', sort } = this;
      if (!isEmpty(sort)) {
        options.sort = {};
        options.sort[sort] = dir;
      } else {
        if (!options.sort) {
          options.sort = {};
        }
        options.sort.certificateNumber = 'asc';
      }

      options.page = this.currentPage;

      await timeout(DEBOUNCE);
      let data = await this.data.listAllPQR(options, this.buildFilters());
      set(this, 'model', data.list);
      set(this, 'totalCount', data.count);
    }
  );

  buildSingleFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(this.get(controllerProperty))) {
      filters[apiProperty] = this.get(controllerProperty);
    }
  }

  buildDecimalFilter(controllerProperty, apiProperty, filters) {
    if (!isEmpty(this.get(controllerProperty))) {
      let decimalValue = this.get(controllerProperty);
      decimalValue = convertDecimals(decimalValue, this.get('userSession.decimalSeparator'));
      filters[apiProperty] = decimalValue;
    }
  }

  buildPWHTFilter(filters) {
    let pwhtPresence = get(this, 'selectedPostWeldHeatTreamentPresence');
    if (!isEmpty(pwhtPresence)) {
      filters.pwhtPresence = pwhtPresence;
    } else {
      let pwhtTemp = get(this, 'selectedPostWeldHeatTreament');
      if (!isNaN(parseFloat(pwhtTemp))) {
        filters.postWeldHeatTreatmentTemperatureTestPiece = pwhtTemp;
      }
    }
  }

  get isThicknessSearchDisabled() {
    return isEmpty(this.selectedUnitSystem);
  }

  get disabledThicknessSearchTooltipText() {
    if (this.isThicknessSearchDisabled) {
      return 'You cannot search for thickness(s)/temperatures(s) without a unit system selected, please select one [i18n]';
    } else {
      return '';
    }
  }

  buildFilters() {
    let filters = {};

    this.buildSingleFilter('selectedStandard.id', PQR.STANDARD, filters);
    this.buildSingleFilter('selectedConstructionStandard.id', PQR.CONSTRUCTION_STANDARD, filters);
    this.buildSingleFilter('selectedExaminingBody.id', PQR.EXAMINING_BODY, filters);
    this.buildSingleFilter('selectedWeldType.id', PQR.WELDTYPE_TESTPIECE, filters);
    this.buildSingleFilter('selectedProcessRoot.id', PQR.WELDING_PROCESS_ROOT_TESTPIECE, filters);
    this.buildSingleFilter('selectedProcessFill.id', PQR.WELDING_PROCESS_FILL_TESTPIECE, filters);
    this.buildSingleFilter('selectedProcessCap.id', PQR.WELDING_PROCESS_CAP_TEST_PIECE, filters);
    this.buildSingleFilter('selectedCompany.id', PQR.COMPANY, filters);
    this.buildSingleFilter('branchAngle', PQR.BRANCH_ANGLE_TESTPIECE, filters);
    this.buildDecimalFilter('heatInput', 'heatInputTestPiece', filters);
    this.buildSingleFilter('preHeat', PQR.PREHEAT_TEMPERATURE_TESTPIECE, filters);
    this.buildSingleFilter(
      'postWeldHeatTreament',
      PQR.POST_WELD_HEAT_TREATMENT_TEMPERATURE_TESTPIECE,
      filters
    );
    this.buildSingleFilter('impactTemperature', PQR.IMPACT_TEST_TEMPERATURE, filters);
    this.buildSingleFilter('selectedUnitSystem.key', PQR.UNIT_SYSTEM, filters);
    if (!isEmpty(this.selectedUnitSystem)) {
      // Since each document has its own unit system, we can only send these thicknesses
      // when the unit system is selected (due to welding standards calculating thickness
      // for imperial/metric differently, we can't simply convert)
      this.buildDecimalFilter('diameter', PQR.DIAMETER_TESTPIECE, filters);
      this.buildDecimalFilter('thickness', PQR.THICKNESS_TOTAL_TESTPIECE, filters);
      this.buildDecimalFilter('thicknessFill', PQR.THICKNESS_FILL_TESTPIECE, filters);
      this.buildDecimalFilter('thicknessRoot', PQR.THICKNESS_ROOT_TESTPIECE, filters);
      this.buildDecimalFilter('thicknessCap', PQR.THICKNESS_CAP_TEST_PIECE, filters);
      this.buildDecimalFilter('baseMaterialThickness', 'baseMaterialThickness', filters);
      this.buildDecimalFilter('baseMaterial1Thickness', 'baseMaterial1Thickness', filters);
      this.buildDecimalFilter('baseMaterial2Thickness', 'baseMaterial2Thickness', filters);
      this.buildDecimalFilter('throatThickness', PQR.THROAT_THICKNESS_TESTPIECE, filters);
    }
    this.buildSingleFilter('hardnessRequirements', PQR.HARDNESS_TEST_REQUIRED, filters);
    this.buildSingleFilter(
      'selectedWeldingPositionRoot.id',
      PQR.WELDING_POSITION_ROOT_TESTPIECE,
      filters
    );
    this.buildSingleFilter(
      'selectedWeldingPositionFill.id',
      PQR.WELDING_POSITION_FILL_TESTPIECE,
      filters
    );
    this.buildSingleFilter(
      'selectedWeldingPositionCap.id',
      PQR.WELDING_POSITION_CAP_TEST_PIECE,
      filters
    );
    this.buildSingleFilter('selectedProductType.key', PQR.PRODUCT_TYPE_TESTPIECE, filters);
    if (!isEmpty(this.baseMaterial)) {
      if (this.searchBaseMaterialByGroup) {
        this.buildSingleFilter('baseMaterial.materialGroup.id', 'baseMaterial1Group', filters);
        this.buildSingleFilter('baseMaterial.materialGroup.id', 'baseMaterial2Group', filters);
      } else {
        this.buildSingleFilter('baseMaterial.id', PQR.BASE_MATERIAL1_TESTPIECE, filters);
        this.buildSingleFilter('baseMaterial.id', PQR.BASE_MATERIAL2_TESTPIECE, filters);
      }
    } else {
      if (this.searchBaseMaterial1ByGroup) {
        this.buildSingleFilter('baseMaterial1.materialGroup.id', 'baseMaterial1Group', filters);
      } else {
        this.buildSingleFilter('baseMaterial1.id', PQR.BASE_MATERIAL1_TESTPIECE, filters);
      }
      if (this.searchBaseMaterial2ByGroup) {
        this.buildSingleFilter('baseMaterial2.materialGroup.id', 'baseMaterial2Group', filters);
      } else {
        this.buildSingleFilter('baseMaterial2.id', PQR.BASE_MATERIAL2_TESTPIECE, filters);
      }
    }
    this.buildSingleFilter(
      'selectedFillerMaterialRoot.id',
      PQR.FILLER_MATERIAL_ROOT_TESTPIECE,
      filters
    );
    this.buildSingleFilter(
      'selectedFillerMaterialFill.id',
      PQR.FILLER_MATERIAL_FILL_TESTPIECE,
      filters
    );
    this.buildSingleFilter(
      'selectedFillerMaterialCap.id',
      PQR.FILLER_MATERIAL_CAP_TEST_PIECE,
      filters
    );
    
    this.buildSingleFilter('selectedOverlayType.key', PQR.OVERLAY_TYPE, filters);
    this.buildSingleFilter('certificateNumber', PQR.CERTIFICATE_NUMBER, filters);
    this.buildSingleFilter('fillerMaterialGroupRootSearch', 'fillerMaterialRootGroup', filters);
    this.buildSingleFilter('fillerMaterialGroupFillSearch', 'fillerMaterialFillGroup', filters);
    this.buildSingleFilter('fillerMaterialGroupCapSearch', 'fillerMaterialCapGroup', filters);
    this.buildPWHTFilter(filters);
    filters.exportType = 'pqr';

    if (!isEmpty(this.selectedDetails)) {
      let detailsSerialized = this.selectedDetails
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters[PQR.WELDING_DETAILS_TESTPIECE] = detailsSerialized;
    }

    if (!isEmpty(this.selectedSpecialRequirements)) {
      let specialRequirementOptionsSerialized = this.selectedSpecialRequirements
        .map((item) => {
          return `${item.get('id')}`;
        })
        .join(',');
      filters[PQR.SPECIAL_REQUIREMENTS] = specialRequirementOptionsSerialized;
    }

    return filters;
  }

  clearProperties() {
    this.setProperties({
      selectedStandard: null,
      selectedConstructionStandard: null,
      selectedDetails: [],
      selectedExaminingBody: null,
      selectedWeldType: null,
      selectedProcessRoot: null,
      selectedProcessFill: null,
      selectedProcessCap: null,
      selectedCompany: null,
      diameter: null,
      diameterDisplay: null,
      thickness: null,
      thicknessDisplay: null,
      selectedProductType: null,
      selectedWeldingPositionRoot: null,
      selectedWeldingPositionFill: null,
      selectedWeldingPositionCap: null,
      branchAngle: null,
      preHeat: null,
      heatInput: null,
      postWeldHeatTreament: null,
      thicknessRoot: null,
      thicknessRootDisplay: null,
      thicknessFill: null,
      thicknessFillDisplay: null,
      thicknessCap: null,
      thicknessCapDisplay: null,
      impactTemperature: null,
      hardnessRequirements: null,
      selectedFillerMaterialRoot: null,
      selectedFillerMaterialFill: null,
      throatThickness: null,
      throatThicknessDisplay: null,
      selectedSpecialRequirements: [],
      selectedOverlayType: null,
      certificateNumber: null,
      selectedPostWeldHeatTreament: null,
      selectedPostWeldHeatTreamentPresence: null,
      fillerMaterialGroupRootSearch: null,
      fillerMaterialGroupFillSearch: null,
      fillerMaterialGroupCapSearch: null,
      baseMaterialThickness: null,
      baseMaterialThicknessDisplay: null,
      baseMaterial1Thickness: null,
      baseMaterial1ThicknessDisplay: null,
      baseMaterial2Thickness: null,
      baseMaterial2ThicknessDisplay: null,
      selectedUnitSystem: null
    });
    this.baseMaterial2 = null;
    this.baseMaterial1 = null;
    this.baseMaterial = null;
    this.searchBaseMaterial1ByGroup = true;
    this.searchBaseMaterial2ByGroup = true;
    this.resetPage();
    this.loadData.perform();
  }

  scrollToSearchResults() {
    this.scroller.scrollVertical('#pqr-search-grid');
  }

  resetPage() {
    set(this, 'currentPage', 1);
  }

  searchPQR(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.loadData.perform({
      userSearch: true,
    });
  }

  @action
  updateStandard(selectedStandard) {
    this.clearProperties();
    set(this, 'selectedStandard', selectedStandard);
    if (isEmpty(this.weldTypeOptionsAll)) {
      this.data.getWeldTypes().then((types) => {
        set(this, 'weldTypeOptionsAll', types);
      });
    }
  }

  @action
  search(pageNumber) {
    set(this, 'currentPage', pageNumber);
    this.searchPQR(pageNumber);
    this.scrollToSearchResults();
  }

  @action
  clearSearch() {
    this.clearProperties();
  }

  @action
  fillerMaterialRootChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialRoot', fillerMaterial);
  }

  @action
  fillerMaterialRootRemoved() {
    set(this, 'selectedFillerMaterialRoot', null);
  }

  @action
  fillerMaterialFillChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialFill', fillerMaterial);
  }

  @action
  fillerMaterialFillRemoved() {
    set(this, 'selectedFillerMaterialFill', null);
  }

  @action
  fillerMaterialCapChosen(fillerMaterial) {
    set(this, 'selectedFillerMaterialCap', fillerMaterial);
  }

  @action
  fillerMaterialCapRemoved() {
    set(this, 'selectedFillerMaterialCap', null);
  }

  @action
  setSelectedWeldType(type) {
    if (isEmpty(type)) {
      set(this, 'selectedOverlayType', null);
      set(this, 'selectedWeldType', null);
    } else {
      set(this, 'selectedWeldType', type);
      if (type.get('weldType') !== WELD_TYPES.OVERLAY_WELD) {
        set(this, 'selectedOverlayType', null);
      }
    }
  }

  @action
  download() {
    let filters = this.buildFilters();
    filters.exportType = 'pqr';
    this.ajax
      .request('/export/get-token', {
        method: 'POST',
        data: JSON.stringify(filters),
        dataType: 'json',
      })
      .then((data) => {
        let namespace = this.store.adapterFor('application').get('namespace');
        let host = `${location.protocol}//${location.hostname}/${namespace}`;
        if (!isEmpty(Env.apiHost)) {
          host = Env.apiHost;
        }
        window.location = `${host}/export/download?token=${data}`;
      });
  }

  @action
  setCertificateNumber(number) {
    set(this, 'certificateNumber', number);
    this.resetPage();
    this.loadData.perform();
  }

  @action
  setThickness(value) {
    set(this, 'thicknessDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'thickness', value);
      }
    } else {
      set(this, 'thickness', value);
    }
  }

  @action
  setThicknessRoot(value) {
    set(this, 'thicknessRootDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'thicknessRoot', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'thicknessRoot', value);
      }
    } else {
      set(this, 'thicknessRoot', value);
    }
  }

  @action
  setThicknessFill(value) {
    set(this, 'thicknessFillDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'thicknessFill', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'thicknessFill', value);
      }
    } else {
      set(this, 'thicknessFill', value);
    }
  }

  @action
  setThicknessCap(value) {
    set(this, 'thicknessCapDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'thicknessCap', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'thicknessCap', value);
      }
    } else {
      set(this, 'thicknessCap', value);
    }
  }

  @action
  setThroatThickness(value) {
    set(this, 'throatThicknessDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'throatThickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'throatThickness', value);
      }
    } else {
      set(this, 'throatThickness', value);
    }
  }

  @action
  setDiameter(value) {
    set(this, 'diameterDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'diameter', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'diameter', value);
      }
    } else {
      set(this, 'diameter', value);
    }
  }

  @action
  setBaseMaterial1Thickness(value) {
    set(this, 'baseMaterial1ThicknessDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterial1Thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterial1Thickness', value);
      }
    } else {
      set(this, 'baseMaterial1Thickness', value);
    }
  }

  @action
  setBaseMaterial2Thickness(value) {
    set(this, 'baseMaterial2ThicknessDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterial2Thickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterial2Thickness', value);
      }
    } else {
      set(this, 'baseMaterial2Thickness', value);
    }
  }

  @action
  setBaseMaterialThickness(value) {
    set(this, 'baseMaterialThicknessDisplay', value);
    if (this.selectedUnitSystem === UNIT_SYSTEM.IMPERIAL) {
      if (isFraction(value)) {
        set(this, 'baseMaterialThickness', toNumber(value, this.decimalSeparator));
      } else {
        set(this, 'baseMaterialThickness', value);
      }
    } else {
      set(this, 'baseMaterialThickness', value);
    }
  }

  @action
  createWps(pqr) {
    this.router.transitionTo('welding-procedure-specification.new-from-pqr', {
      queryParams: { pqr: get(pqr, 'id') },
    });
  }

  @action
  setDissimilarMaterials(value) {
    if (value) {
      set(this, 'baseMaterialThickness', null);
      this.baseMaterial = null;
      this.searchBaseMaterialByGroup = true;
    } else {
      this.baseMaterial1 = null;
      this.baseMaterial2 = null;
      this.searchBaseMaterial1ByGroup = true;
      this.searchBaseMaterial2ByGroup = true;
      set(this, 'baseMaterial1Thickness', null);
      set(this, 'baseMaterial2Thickness', null);
    }
    set(this, 'dissimilarMaterials', value);
  }

  @action
  displayHelpVideo() {
    set(this, 'showVideo', true);
    let { analytics, help } = this;
    analytics.trackEvent(help.convertVideoIdToAnalyticsEvent(help.pqrVideo), {
      location: 'pqr-list',
    });
  }

  @action
  setBaseMaterial1(material) {
    this.baseMaterial1 = material;
    if (material && material.isNA) {
      this.searchBaseMaterial1ByGroup = false;
    }
  }

  @action
  removeBaseMaterial1() {
    this.baseMaterial1 = null;
    this.searchBaseMaterial1ByGroup = true;
  }

  @action
  setBaseMaterial2(material) {
    this.baseMaterial2 = material;
    if (material && material.isNA) {
      this.searchBaseMaterial2ByGroup = false;
    }
  }

  @action
  removeBaseMaterial2() {
    this.baseMaterial2 = null;
    this.searchBaseMaterial2ByGroup = true;
  }

  @action
  setBaseMaterial(material) {
    this.baseMaterial = material;
    if (material && material.isNA) {
      this.searchBaseMaterialByGroup = false;
    }
  }

  @action
  removeBaseMaterial() {
    this.baseMaterial = null;
    this.searchBaseMaterialByGroup = false;
  }

  @action
  setSearchByMaterialGroup(value) {
    this.searchBaseMaterialByGroup = value;
  }

  @action
  setSearchByMaterial1Group(value) {
    this.searchBaseMaterial1ByGroup = value;
  }

  @action
  setSearchByMaterial2Group(value) {
    this.searchBaseMaterial2ByGroup = value;
  }
}
