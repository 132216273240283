import { service } from '@ember/service';
import { attr, belongsTo, hasMany, SyncHasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { memberAction } from 'ember-api-actions';
import BaseModel from 'weldnote/models/base-model';
import { calculateShielding } from 'weldnote/utils/certification-rules';
import Constants from 'weldnote/utils/constants';
import { isPartialPenetrationButtWeldASME } from 'weldnote/utils/rules/weld-type';
import { isBothSides } from 'weldnote/utils/rules/welding-detail';
import {
  isStandardASME,
  isStandardAWSD11,
  isWelderStandardISO96061,
} from 'weldnote/utils/standards';
import { thicknessValidation } from 'weldnote/utils/validation/thickness';
import { weldingDetailValidation } from 'weldnote/utils/validation/welding-details';
import WelderCertificateRules from 'weldnote/utils/welder-certificate-rules';
import ExaminingBody from './examining-body';
import Welder from './welder';
import Company from './company';
import WelderStandard from './welder-standard';
import ConstructionStandard from './construction-standard';
import WeldingProcess from './welding-process';
import WeldType from './weld-type';
import ArcTransferMode from './arc-transfer-mode';
import WeldingDetail from './welding-detail';
import WeldingPosition from './welding-position';
import TypeCurrentPolarity from './type-current-polarity';
import BaseMaterial from './base-material';
import FillerMaterial from './filler-material';
import FillerMaterialCommercialDesignation from './filler-material-commercial-designation';
import ElectrodeType from './electrode-type';
import Gas from './gas';
import GasCommercialDesignation from './gas-commercial-designation';
import Flux from './flux';
import FluxCommercialDesignation from './flux-commercial-designation';
import BaseMaterialGroup from './base-material-group';
import FillerMaterialGroup from './filler-material-group';
import FileUpload from './file-upload';
import CertificationSpecialRequirement from './certification-special-requirement';
import WelderCertificateRevalidation from './welder-certificate-revalidation';
import ProcessMecanization from './process-mecanization';
import UnitSystemService from 'weldnote/services/unit-system';
import UserSessionService from 'weldnote/services/user-session';

const { CERTIFICATE_STATE, CERTIFICATE_TYPE, PRODUCT_TYPE } = Constants;

const { WELDER_CERTIFICATE } = WelderCertificateRules;

export class WelderCertificate extends BaseModel {
  @service
  declare userSession: UserSessionService;

  @service('unit-system')
  declare units: UnitSystemService;

  @attr('string')
  declare certificateNumber?: string;

  @belongsTo('examining-body', { async: false })
  declare examiningBody: ExaminingBody;

  @attr('string')
  declare designation?: string;

  @belongsTo('welder', { async: false })
  declare welder: Welder;

  @belongsTo('company', { async: false })
  declare company: Company;

  @belongsTo('welder-standard', { async: false })
  declare standard: WelderStandard;

  @belongsTo('construction-standard', { async: false })
  declare constructionStandard: ConstructionStandard;

  @attr('string')
  declare jobKnowledge?: string;

  @attr('date')
  declare emissionDate?: Date;

  @attr('date')
  declare previousExternalRequalificationDate?: Date;

  @attr('date')
  declare internalRequalificationDate?: Date;

  @attr('date')
  declare internalRequalificationDeadline?: Date;

  @attr('date')
  declare externalRequalificationDeadline?: Date;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessRootTestPiece: WeldingProcess;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessFillTestPiece: WeldingProcess;

  @belongsTo('weld-type', { async: false })
  declare weldTypeTestPiece: WeldType;

  @belongsTo('welder-certificate', { inverse: 'supplementaryFilletWeldTest', async: false })
  declare supplementaryFilletWeldTest: WelderCertificate;

  @attr('boolean')
  declare supplementaryFilletWeldTestRequired?: boolean;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeRootTestPiece: ArcTransferMode;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeFillTestPiece: ArcTransferMode;

  @hasMany('welding-detail', { async: false })
  declare weldingDetailTestPiece: SyncHasMany<WeldingDetail>;

  @belongsTo('welding-position', { async: false })
  declare weldingPositionRootTestPiece: WeldingPosition;

  @belongsTo('welding-position', { async: false })
  declare weldingPositionFillTestPiece: WeldingPosition;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentRootTestPiece: TypeCurrentPolarity;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentFillTestPiece: TypeCurrentPolarity;

  @belongsTo('base-material', { async: false })
  declare baseMaterial1TestPiece: BaseMaterial;

  @belongsTo('base-material', { async: false })
  declare baseMaterial2TestPiece: BaseMaterial;

  @attr('string')
  declare productTypeTestPiece?: string;

  @attr('decimal-value')
  declare thicknessRootTestPiece?: number;

  @attr('string')
  declare thicknessRootLayerTestPiece?: string;

  @attr('decimal-value')
  declare thicknessFillTestPiece?: number;

  @attr('string')
  declare thicknessFillLayerTestPiece?: string;

  @attr('decimal-value')
  declare thicknessTotalTestPiece?: number;

  @attr('decimal-value')
  declare diameterTestPiece?: number;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialRootTestPiece: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', { async: false })
  declare fillerMaterialRootCommercialDesignationTestPiece: FillerMaterialCommercialDesignation;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialFillTestPiece: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', { async: false })
  declare fillerMaterialFillCommercialDesignationTestPiece: FillerMaterialCommercialDesignation;

  @belongsTo('electrode-type', { async: false })
  declare electrodeTypeRootTestPiece: ElectrodeType;

  @belongsTo('electrode-type', { async: false })
  declare electrodeTypeFillTestPiece: ElectrodeType;

  @attr('string')
  declare shieldingRoot?: string;

  @belongsTo('gas', { async: false })
  declare shieldingRootGasTestPiece: Gas;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare shieldingRootGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @attr('string')
  declare shieldingRootGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingRootFluxTestPiece: Flux;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingRootFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @attr('string')
  declare flowRateRoot?: string;

  @attr('string')
  declare shieldingFill?: string;

  @belongsTo('gas', { async: false })
  declare shieldingFillGasTestPiece: Gas;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare shieldingFillGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @attr('string')
  declare shieldingFillGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingFillFluxTestPiece: Flux;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingFillFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @attr('string')
  declare flowRateFill?: string;

  @attr('string')
  declare backingTestPiece?: string;

  @belongsTo('gas', { async: false })
  declare backingGasTestPiece: Gas;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare backingGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @attr('string')
  declare backingGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare backingFluxTestPiece: Flux;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare backingFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @attr('string')
  declare backingFlowRateRoot?: string;

  @attr('string')
  declare backingFlowRateFill?: string;

  @hasMany('welding-process', { async: false })
  declare weldingProcessRootApprovalRange: SyncHasMany<WeldingProcess>;

  @hasMany('welding-process', { async: false })
  declare weldingProcessFillApprovalRange: SyncHasMany<WeldingProcess>;

  @hasMany('weld-type', { async: false })
  declare weldTypeApprovalRange: SyncHasMany<WeldType>;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModeRootApprovalRange: SyncHasMany<ArcTransferMode>;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModeFillApprovalRange: SyncHasMany<ArcTransferMode>;

  @hasMany('welding-detail', { async: false })
  declare weldingDetailsApprovalRange: SyncHasMany<WeldingDetail>;

  @hasMany('welding-position', { async: false })
  declare weldingPositionRootApprovalRange: SyncHasMany<WeldingPosition>;

  @hasMany('welding-position', { async: false })
  declare weldingPositionFillApprovalRange: SyncHasMany<WeldingPosition>;

  @hasMany('base-material-group', { async: false })
  declare baseMaterial1ApprovalRange: SyncHasMany<BaseMaterialGroup>;

  @hasMany('base-material-group', { async: false })
  declare baseMaterial2ApprovalRange: SyncHasMany<BaseMaterialGroup>;

  @attr('decimal-value')
  declare thicknessRootMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessRootMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessFillMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessFillMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessTotalMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare thicknessTotalMaximumApprovalRange?: number;

  @attr('decimal-value')
  declare diameterMinimumApprovalRange?: number;

  @attr('decimal-value')
  declare diameterMaximumApprovalRange?: number;

  @hasMany('filler-material-group', { async: false })
  declare fillerMaterialRootApprovalRange: SyncHasMany<FillerMaterialGroup>;

  @hasMany('filler-material-group', { async: false })
  declare fillerMaterialFillApprovalRange: SyncHasMany<FillerMaterialGroup>;

  @hasMany('filler-material-group', { async: false })
  declare fillerMaterialCapApprovalRange: SyncHasMany<FillerMaterialGroup>;

  @hasMany('electrode-type', { async: false })
  declare electrodeTypeRootApprovalRange: SyncHasMany<ElectrodeType>;

  @hasMany('electrode-type', { async: false })
  declare electrodeTypeFillApprovalRange: SyncHasMany<ElectrodeType>;

  @hasMany('gas', { async: false })
  declare shieldingRootGasApprovalRange: SyncHasMany<Gas>;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare shieldingRootGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @hasMany('flux', { async: false })
  declare shieldingRootFluxApprovalRange: SyncHasMany<Flux>;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare shieldingRootFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @hasMany('gas', { async: false })
  declare shieldingFillGasApprovalRange: SyncHasMany<Gas>;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare shieldingFillGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @hasMany('flux', { async: false })
  declare shieldingFillFluxApprovalRange: SyncHasMany<Flux>;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare shieldingFillFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @hasMany('gas', { async: false })
  declare backingGasApprovalRange: SyncHasMany<Gas>;

  @hasMany('gas-commercial-designation', {
    async: false,
  })
  declare backingGasCommercialDesignationApprovalRange: SyncHasMany<GasCommercialDesignation>;

  @hasMany('flux', { async: false })
  declare backingFluxApprovalRange: SyncHasMany<Flux>;

  @hasMany('flux-commercial-designation', {
    async: false,
  })
  declare backingFluxCommercialDesignationApprovalRange: SyncHasMany<FluxCommercialDesignation>;

  @attr('number')
  declare requalificationPeriod?: number;

  @belongsTo('file-upload', { async: false })
  declare certificateDocument: FileUpload;

  @attr('string')
  declare certificateDocumentPath?: string;

  @attr('string')
  declare certificateState?: string;

  @belongsTo('welding-process', { async: false })
  declare weldingProcessCapTestPiece: WeldingProcess;

  @hasMany('welding-process', { async: false })
  declare weldingProcessCapApprovalRange: SyncHasMany<WeldingProcess>;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferModeCapTestPiece: ArcTransferMode;

  @hasMany('arc-transfer-mode', { async: false })
  declare arcTransferModeCapApprovalRange: SyncHasMany<ArcTransferMode>;

  @belongsTo('welding-position', { async: false })
  declare weldingPositionCapTestPiece: WeldingPosition;

  @hasMany('welding-position', { async: false })
  declare weldingPositionCapApprovalRange: SyncHasMany<WeldingPosition>;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrentCapTestPiece: TypeCurrentPolarity;

  @attr('number')
  declare thicknessCapTestPiece?: number;

  @attr('string')
  declare thicknessCapLayerTestPiece?: string;

  @attr('number')
  declare thicknessCapMinimumApprovalRange?: number;

  @attr('number')
  declare thicknessCapMaximumApprovalRange?: number;

  @belongsTo('filler-material', { async: false })
  declare fillerMaterialCapTestPiece: FillerMaterial;

  @belongsTo('filler-material-commercial-designation', { async: false })
  declare fillerMaterialCapCommercialDesignationTestPiece: FillerMaterialCommercialDesignation;

  @belongsTo('electrode-type', { async: false })
  declare electrodeTypeCapTestPiece: ElectrodeType;

  @hasMany('electrode-type', { async: false })
  declare electrodeTypeCapApprovalRange: SyncHasMany<ElectrodeType>;

  @attr('string')
  declare shieldingCap?: string;

  @belongsTo('gas', { async: false })
  declare shieldingCapGasTestPiece: Gas;

  @belongsTo('gas-commercial-designation', {
    async: false,
  })
  declare shieldingCapGasCommercialDesignationTestPiece: GasCommercialDesignation;

  @attr('string')
  declare shieldingCapGasMixture?: string;

  @belongsTo('flux', { async: false })
  declare shieldingCapFluxTestPiece: Flux;

  @belongsTo('flux-commercial-designation', {
    async: false,
  })
  declare shieldingCapFluxCommercialDesignationTestPiece: FluxCommercialDesignation;

  @attr('string')
  declare flowRateCap?: string;

  @attr('string')
  declare backingFlowRateCap?: string;

  @attr('number')
  declare baseMaterial1Thickness?: number;

  @attr('number')
  declare baseMaterial1Diameter?: number;

  @attr('number')
  declare baseMaterial2Thickness?: number;

  @attr('number')
  declare baseMaterial2Diameter?: number;

  @attr('string')
  declare weldingPositionRootDirection?: string;

  @attr('string')
  declare weldingPositionFillDirection?: string;

  @attr('string')
  declare weldingPositionCapDirection?: string;

  @hasMany('certification-special-requirement', { async: false })
  declare specialRequirements: SyncHasMany<CertificationSpecialRequirement>;

  @attr('string')
  declare overlayType?: string;

  @attr('string')
  declare jointType?: string;

  @belongsTo('file-upload', { async: false })
  declare certificateReportDocument: FileUpload;

  @attr('string')
  declare certificateReportDocumentPath?: string;

  @attr('boolean')
  declare certificateReportOutdated?: boolean;

  @belongsTo('file-upload', { async: false })
  declare penetrantTestDocument: FileUpload;

  @attr('string')
  declare penetrantTestDocumentPath?: string;

  @attr('string')
  declare penetrantTestResult?: string;

  @attr('string')
  declare penetrantTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare magneticTestDocument: FileUpload;

  @attr('string')
  declare magneticTestDocumentPath?: string;

  @attr('string')
  declare magneticTestResult?: string;

  @attr('string')
  declare magneticTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare ultrasonicTestDocument: FileUpload;

  @attr('string')
  declare ultrasonicTestDocumentPath?: string;

  @attr('string')
  declare ultrasonicTestResult?: string;

  @attr('string')
  declare ultrasonicTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare radiographicTestDocument: FileUpload;

  @attr('string')
  declare radiographicTestDocumentPath?: string;

  @attr('string')
  declare radiographicTestResult?: string;

  @attr('string')
  declare radiographicTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare visualTestDocument: FileUpload;

  @attr('string')
  declare visualTestDocumentPath?: string;

  @attr('string')
  declare visualTestResult?: string;

  @attr('string')
  declare visualTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare tensileTestDocument: FileUpload;

  @attr('string')
  declare tensileTestDocumentPath?: string;

  @attr('string')
  declare tensileTestResult?: string;

  @attr('string')
  declare tensileTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare bendTestDocument: FileUpload;

  @attr('string')
  declare bendTestDocumentPath?: string;

  @attr('string')
  declare bendTestResult?: string;

  @attr('string')
  declare bendTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare macrographicTestDocument: FileUpload;

  @attr('string')
  declare macrographicTestDocumentPath?: string;

  @attr('string')
  declare macrographicTestResult?: string;

  @attr('string')
  declare macrographicTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare impactTestDocument: FileUpload;

  @attr('string')
  declare impactTestDocumentPath?: string;

  @attr('string')
  declare impactTestResult?: string;

  @attr('string')
  declare impactTestNumber?: string;

  @belongsTo('file-upload', { async: false })
  declare fractureTestDocument: FileUpload;

  @attr('string')
  declare fractureTestDocumentPath?: string;

  @attr('string')
  declare fractureTestResult?: string;

  @attr('string')
  declare fractureTestNumber?: string;

  @hasMany('welder-certificate-revalidation', { async: false })
  declare revalidations: SyncHasMany<WelderCertificateRevalidation>;

  @attr('string')
  declare testConductedBy?: string;

  @belongsTo('welding-procedure-specification', { async: false })
  declare wps: any;

  @attr('string')
  declare notes?: string;

  @attr('string')
  declare certificateType?: string;

  @attr('string')
  declare arcLengthControlProcess1TestPiece?: string | null;

  @attr('string')
  declare visualControlProcess1TestPiece?: string | null;

  @attr('string')
  declare jointTrackingProcess1TestPiece?: string | null;

  @attr('string')
  declare arcLengthControlProcess2TestPiece?: string | null;

  @attr('string')
  declare visualControlProcess2TestPiece?: string | null;

  @attr('string')
  declare jointTrackingProcess2TestPiece?: string | null;

  @attr('string')
  declare arcLengthControlProcess3TestPiece?: string | null;

  @attr('string')
  declare visualControlProcess3TestPiece?: string | null;

  @attr('string')
  declare jointTrackingProcess3TestPiece?: string | null;

  @attr('string')
  declare remoteWeldingUnitId?: string | null;

  @attr('string')
  declare remoteWeldingUnitDisplayName?: string | null;

  @attr('string')
  declare weldingOperatorWeldingDetailsProcess1TestPiece?: string | null;

  @attr('string')
  declare weldingOperatorWeldingDetailsProcess2TestPiece?: string | null;

  @attr('string')
  declare weldingOperatorWeldingDetailsProcess3TestPiece?: string | null;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationRootTestPiece: ProcessMecanization | null;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationFillTestPiece: ProcessMecanization | null;

  @belongsTo('process-mecanization', { async: false })
  declare processMecanizationCapTestPiece: ProcessMecanization | null;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizationRootApprovalRange: SyncHasMany<ProcessMecanization>;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizationFillApprovalRange: SyncHasMany<ProcessMecanization>;

  @hasMany('process-mecanization', { async: false })
  declare processMecanizationCapApprovalRange: SyncHasMany<ProcessMecanization>;

  @attr('string')
  declare unitSystem?: string | null;

  get visualLabel() {
    return this.certificateNumber;
  }

  validations = {
    certificateNumber: {
      presence: {
        message(_key: string, _value: string, model: WelderCertificate): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    unitSystem: {
      presence: {
        message(_key: string, _value: string, model: WelderCertificate): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    standard: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    thicknessRootTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: thicknessValidation(
        WELDER_CERTIFICATE.THICKNESS_ROOT_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_FILL_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_CAP_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_TOTAL_TEST_PIECE
      ),
    },

    thicknessFillTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: thicknessValidation(
        WELDER_CERTIFICATE.THICKNESS_ROOT_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_FILL_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_CAP_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_TOTAL_TEST_PIECE
      ),
    },

    thicknessTotalTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    diameterTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessRootMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessRootMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessFillMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessFillMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessTotalMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessTotalMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    diameterMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    diameterMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    requalificationPeriod: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 20,
        onlyInteger: true,
      },

      custom: {
        validation(_key: string, value: any, model: WelderCertificate) {
          let { standard } = model;
          if (isStandardASME(standard) || isStandardAWSD11(standard)) {
            return true;
          }
          if (
            !isEmpty(get(model, 'previousExternalRequalificationDate')) ||
            !isEmpty(get(model, 'externalRequalificationDeadline'))
          ) {
            return !isEmpty(value);
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          return model.translate(
            'model-validations.welder-certificate.requalificationPeriod.required-by-date'
          );
        },
      },
    },

    previousExternalRequalificationDate: {
      custom: {
        validation(_key: string, value: any, model: WelderCertificate) {
          if (!isEmpty(model.get('externalRequalificationDeadline'))) {
            return !isEmpty(value);
          }
          if (!isEmpty(model.get('requalificationPeriod'))) {
            return !isEmpty(value);
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          if (!isEmpty(model.get('externalRequalificationDeadline'))) {
            return model.translate(
              'model-validations.welder-certificate.previousExternalRequalificationDate.required-by-deadline'
            );
          }
          if (!isEmpty(model.get('requalificationPeriod'))) {
            return model.translate(
              'model-validations.welder-certificate.previousExternalRequalificationDate.required-by-period'
            );
          }
          return '';
        },
      },
    },

    internalRequalificationDate: {
      custom: {
        validation(_key: string, value: any, model: WelderCertificate) {
          if (!isEmpty(model.get('internalRequalificationDeadline'))) {
            return !isEmpty(value);
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          return model.translate(
            'model-validations.welder-certificate.internalRequalificationDate.required-by-deadline'
          );
        },
      },
    },

    internalRequalificationDeadline: {
      custom: {
        validation(_key: string, value: any, model: WelderCertificate) {
          if (!isEmpty(model.get('internalRequalificationDate'))) {
            return !isEmpty(value);
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          return model.translate(
            'model-validations.welder-certificate.internalRequalificationDeadline.required-by-date'
          );
        },
      },
    },

    externalRequalificationDeadline: {
      custom: {
        validation(_key: string, value: any, model: WelderCertificate) {
          if (isWelderStandardISO96061(model.standard)) {
            return true;
          }
          if (!isEmpty(model.get('requalificationPeriod'))) {
            return !isEmpty(value);
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          return model.translate(
            'model-validations.welder-certificate.externalRequalificationDeadline.required-by-period'
          );
        },
      },
    },

    thicknessCapTestPiece: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },

      custom: thicknessValidation(
        WELDER_CERTIFICATE.THICKNESS_ROOT_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_FILL_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_CAP_TEST_PIECE,
        WELDER_CERTIFICATE.THICKNESS_TOTAL_TEST_PIECE
      ),
    },

    thicknessCapMinimumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    thicknessCapMaximumApprovalRange: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial1Thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial1Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial2Thickness: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    baseMaterial2Diameter: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0.0,
        lessThanOrEqualTo: 9999.99,
      },
    },

    shieldingRoot: {
      custom: {
        if() {
          // Disable the validation because of errors for customers
          // this needs to be either removed, or enabled correctly
          return false;
        },

        validation(_key: string, value: any, model: WelderCertificate) {
          let { weldingProcessRootTestPiece: weldingProcessRoot } = model;
          if (!isEmpty(weldingProcessRoot)) {
            let weldingProcessCode = get(weldingProcessRoot, 'shortDesignation');
            let correctShielding = calculateShielding(weldingProcessCode);
            if (!isEmpty(correctShielding)) {
              return correctShielding === value;
            }
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          let { weldingProcessRootTestPiece: weldingProcessRoot } = model;
          let process = get(weldingProcessRoot, 'shortDesignation');
          let correctShielding = calculateShielding(process);
          let shielding = model.translate(`lov.shielding.${correctShielding}`);
          return model
            .translate('model-validations.shielding.root', {
              process,
              shielding,
            })
            .toString();
        },
      },
    },

    shieldingFill: {
      custom: {
        if() {
          // Disable the validation because of errors for customers
          // this needs to be either removed, or enabled correctly
          return false;
          // return !isEmpty(value);
        },

        validation(_key: string, value: any, model: WelderCertificate) {
          let { weldingProcessFillTestPiece: weldingProcessFill } = model;
          if (!isEmpty(weldingProcessFill)) {
            let weldingProcessCode = get(weldingProcessFill, 'shortDesignation');
            let correctShielding = calculateShielding(weldingProcessCode);
            if (!isEmpty(correctShielding)) {
              return correctShielding === value;
            }
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          let { weldingProcessFillTestPiece: weldingProcessFill } = model;
          let process = get(weldingProcessFill, 'shortDesignation');
          let correctShielding = calculateShielding(process);
          let shielding = model.translate(`lov.shielding.${correctShielding}`);
          return model
            .translate('model-validations.shielding.fill', {
              process,
              shielding,
            })
            .toString();
        },
      },
    },

    shieldingCap: {
      custom: {
        if() {
          // Disable the validation because of errors for customers
          // this needs to be either removed, or enabled correctly
          return false;
          // return !isEmpty(value);
        },

        validation(_key: string, value: any, model: WelderCertificate) {
          let { weldingProcessCapTestPiece: weldingProcessCap } = model;
          if (!isEmpty(weldingProcessCap)) {
            let weldingProcessCode = get(weldingProcessCap, 'shortDesignation');
            let correctShielding = calculateShielding(weldingProcessCode);
            if (!isEmpty(correctShielding)) {
              return correctShielding === value;
            }
          }
          return true;
        },

        message(_key: string, _value: any, model: WelderCertificate) {
          let { weldingProcessCapTestPiece: weldingProcessCap } = model;
          let process = get(weldingProcessCap, 'shortDesignation');
          let correctShielding = calculateShielding(process);
          let shielding = model.translate(`lov.shielding.${correctShielding}`);
          return model
            .translate('model-validations.shielding.cap', {
              process,
              shielding,
            })
            .toString();
        },
      },
    },

    weldingDetailTestPiece: {
      custom: weldingDetailValidation,
    },
  };

  metadata = {
    modelName: 'welder-certificate',
    listRoute: 'welder-certificates',
    editRoute: 'welder-certificate',
    certificateNumber: { required: true },
    standard: { required: true },
    unitSystem: { required: true }
  };

  get isArchived() {
    return this.certificateState === CERTIFICATE_STATE.ARCHIVED;
  }

  get isInternallyExpired(): boolean {
    return this.certificateState === CERTIFICATE_STATE.INTERNALLY_EXPIRED;
  }

  get isExternallyExpired(): boolean {
    return this.certificateState === CERTIFICATE_STATE.EXTERNALLY_EXPIRED;
  }

  get isWelderCertificate(): boolean {
    return this.certificateType === CERTIFICATE_TYPE.WELDER;
  }

  get isWelderOperatorCertificate(): boolean {
    return this.certificateType === CERTIFICATE_TYPE.OPERATOR;
  }

  get hasAttachement(): boolean {
    return !isEmpty(this.certificateDocumentPath);
  }

  getIndustryCode() {
    let { standard } = this;
    if (!isEmpty(standard)) {
      let code = get(standard, 'code');
      if (!isEmpty(code)) {
        let codeId = get(code, 'id');
        return codeId;
      }
    }
    return null;
  }

  isBothSides(): boolean {
    if (this.weldingDetailTestPiece) {
      return isBothSides(this.weldingDetailTestPiece.toArray());
    }
    return false;
  }

  isPartialPenetrationButtWeld() {
    return isPartialPenetrationButtWeldASME(this.weldTypeTestPiece);
  }

  get isPlate() {
    return this.productTypeTestPiece === PRODUCT_TYPE.PLATE;
  }

  get isPipe() {
    return this.productTypeTestPiece === PRODUCT_TYPE.PIPE;
  }

  get isOverlayWeld() {
    let weldType = get(this, 'weldTypeTestPiece');
    if (weldType) {
      return weldType.isOverlayWeld;
    }
    return false;
  }

  get temperatureUnit() {
    return this.units.getTemperatureUnit(this.unitSystem || '');
  }
  get distanceUnit() {
    return this.units.getDistanceUnit(this.unitSystem || '');
  }
  get flowUnit() {
    return this.units.getFlowUnit(this.unitSystem || '');
  }
  get heatDistanceUnit() {
    return this.units.getHeatDistanceUnit(this.unitSystem || '');
  }
  get travelSpeedUnit() {
    return this.units.getTravelSpeedUnit(this.unitSystem || '');
  }
  get wireSpeedUnit() {
    return this.units.getWireSpeedUnit(this.unitSystem || '');
  }
  get pressureUnit() {
    return this.units.getPressureUnit(this.unitSystem || '');
  }
  get forceUnit() {
    return this.units.getForceUnit(this.unitSystem || '');
  }
  get areaUnit() {
    return this.units.getAreaUnit(this.unitSystem || '');
  }
  get energyUnit() {
    return this.units.getEnergyUnit(this.unitSystem || '');
  }

  validateInternallyToday = memberAction({ path: 'validateInternallyToday' });

  validateInternally30Days = memberAction({ path: 'validateInternally30Days' });

  validateInternallyExpired = memberAction({ path: 'validateInternallyExpired' });

  validateExternallyToday = memberAction({ path: 'validateExternallyToday' });

  validateExternally30Days = memberAction({ path: 'validateExternally30Days' });

  validateExternallyExpired = memberAction({ path: 'validateExternallyExpired' });

  archive = memberAction({ path: 'archiveCertificate' });

  unarchive = memberAction({ path: 'unarchiveCertificate' });

  duplicateCertificate = memberAction({ path: 'duplicateCertificate' });

  createReport = memberAction({ path: 'createReport', type: 'PUT' });
}

export default WelderCertificate;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'welder-certificate': WelderCertificate;
  }
}
