import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';
import { set } from '@ember/object';

export default class WeldingProcedureSpecificationsRoute extends ListRoute {
  @service('weldnote-data')
  data;

  @service('weldcloud-notes-data')
  notesData;

  model() {
    let sortOptions = {
      wpsNumber: 'asc',
    };
    return this.data.listAllWeldingProcedureSpecifications({ sort: sortOptions }, {});
  }

  setupController(controller, model) {
    set(controller, 'model', model.list);
    set(controller, 'totalCount', model.count);
    set(controller, 'selectedUnitSystem', this.notesData.getUnitSystem(this.userSession.unitSystem));
  }

}
