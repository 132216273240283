import { AsyncBelongsTo, attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { memberAction } from 'ember-api-actions';
import BaseModel from 'weldnote/models/base-model';
import Attributes from 'weldnote/pods/project/attributes';
import Constants from 'weldnote/utils/constants';
import { isSamePQRAndWelderStandard } from 'weldnote/utils/standards';
import Company from './company';
import ConstructionStandard from './construction-standard';
import PqrStandard from './pqr-standard';
import WelderStandard from './welder-standard';
import FileUpload from './file-upload';
import ProjectClient from './project-client';
import { isEmpty } from '@ember/utils';
import UnitSystemService from 'weldnote/services/unit-system';
import { service } from '@ember/service';

const { PROJECT } = Attributes;

const { ARCHIVAL_STATE, WPS_INCLUSION } = Constants;

export default class Project extends BaseModel {

  @service('unit-system')
  declare units: UnitSystemService;

  @attr('string')
  declare designation?: string;

  @attr('string')
  declare projectName?: string;

  @belongsTo('company')
  declare supplier: AsyncBelongsTo<Company>;

  @belongsTo('construction-standard')
  declare constructionStandard: AsyncBelongsTo<ConstructionStandard>;

  @belongsTo('pqr-standard')
  declare standard: AsyncBelongsTo<PqrStandard>;

  @belongsTo('welder-standard')
  declare welderStandard: AsyncBelongsTo<WelderStandard>;

  @attr('string', { defaultValue: 'PENDING' })
  declare projectStatus: string;

  @attr('date')
  declare startDate?: Date;

  @attr('date')
  declare endDate?: Date;

  @hasMany('pqr')
  declare pqrs: any;

  @belongsTo('file-upload')
  declare projectReport: AsyncBelongsTo<FileUpload>;

  @attr('string')
  declare projectReportPath?: string;

  @attr('number', { defaultValue: 2 })
  declare maximumRepairsPerWeld: number;

  @attr('boolean')
  declare selectWeldersFromCompanyOnly?: boolean;

  @attr('boolean')
  declare selectWpsFromCompanyOnly?: boolean;

  @attr('boolean')
  declare selectWpsFromProjectLibraryOnly?: boolean;

  @belongsTo('file-upload')
  declare coverPicture: AsyncBelongsTo<FileUpload>;

  @belongsTo('project-client')
  declare projectClient: AsyncBelongsTo<ProjectClient>;

  @attr('string', { defaultValue: ARCHIVAL_STATE.ACTIVE })
  declare archivalState: string;

  @attr('string', { defaultValue: WPS_INCLUSION.GENERATE_PDF })
  declare typeOfWpsInclusion: string;

  @attr('number')
  declare totalWelds?: number;

  @attr('number')
  declare goodWelds?: number;

  @attr('number')
  declare repairedWelds?: number;

  @attr('number')
  declare repairRate?: number;

  @attr('string')
  declare unitSystem?: string;

  get visualLabel() {
    if (!isEmpty(this.projectName)) {
      return this.projectName;
    } else {
      return this.designation;
    }
  }

  get temperatureUnit() {
    return this.units.getTemperatureUnit(this.unitSystem || '');
  }
  get distanceUnit() {
    return this.units.getDistanceUnit(this.unitSystem || '');
  }
  get flowUnit() {
    return this.units.getFlowUnit(this.unitSystem || '');
  }
  get heatDistanceUnit() {
    return this.units.getHeatDistanceUnit(this.unitSystem || '');
  }
  get travelSpeedUnit() {
    return this.units.getTravelSpeedUnit(this.unitSystem || '');
  }
  get wireSpeedUnit() {
    return this.units.getWireSpeedUnit(this.unitSystem || '');
  }
  get pressureUnit() {
    return this.units.getPressureUnit(this.unitSystem || '');
  }
  get forceUnit() {
    return this.units.getForceUnit(this.unitSystem || '');
  }
  get areaUnit() {
    return this.units.getAreaUnit(this.unitSystem || '');
  }
  get energyUnit() {
    return this.units.getEnergyUnit(this.unitSystem || '');
  }

  validations = {
    designation: { presence: true },
    unitSystem: { presence: true },
    standard: {
      custom: [
        {
          validation(_key: string, value: any, model: any): boolean {
            let pqrStandard = value;
            let welderStandard = get(model, PROJECT.WELDER_STANDARD);
            return isSamePQRAndWelderStandard(pqrStandard, welderStandard);
          },

          message(_key: string, _value: any, model: any): string {
            return model.translate('model-validations.welding-book.standard');
          },
        },
        {
          validation(_key: string, value: any) {
            return !isEmpty(value) && !isEmpty(get(value, 'id'));
          },

          message(_key: string, _value: string, model: BaseModel): string {
            return model.translate('generic.error.input-value');
          },
        },
      ],
    },

    welderStandard: {
      custom: [
        {
          validation(_key: string, value: any, model: any): boolean {
            let welderStandard = value;
            let pqrStandard = get(model, PROJECT.STANDARD);
            return isSamePQRAndWelderStandard(pqrStandard, welderStandard);
          },

          message(_key: string, _value: any, model: any): string {
            return model.translate('model-validations.welding-book.welder-standard');
          },
        },
        {
          validation(_key: string, value: any) {
            return !isEmpty(value) && !isEmpty(get(value, 'id'));
          },

          message(_key: string, _value: string, model: BaseModel): string {
            return model.translate('generic.error.input-value');
          },
        },
      ],
    },

    projectClient: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    supplier: {
      presence: {
        message(_key: string, _value: any, model: any): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  metadata = {
    modelName: 'project',
    listRoute: 'projects',
    editRoute: 'project',
    designation: { required: true },
    standard: { required: true },
    welderStandard: { required: true },
    projectClient: { required: true },
    supplier: { required: true },
    unitSystem: { required: true }
  };

  get isArchived() {
    return this.archivalState === ARCHIVAL_STATE.ARCHIVED;
  }

  get isActive() {
    return this.archivalState === ARCHIVAL_STATE.ACTIVE;
  }

  createProjectReport = memberAction({ path: 'createProjectReport' });

  archive = memberAction({ path: 'archiveProject', type: 'PUT' });

  unarchive = memberAction({ path: 'unarchiveProject', type: 'PUT' });

  duplicate = memberAction({ path: 'duplicateProject', type: 'PUT' });

  exportWelds = memberAction({ path: 'exportWelds', type: 'POST' });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    project: Project;
  }
}
