import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { not, sort, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import WeldMapRules from 'weldnote/utils/welding-map-rules';
import { tracked } from '@glimmer/tracking';

const { WELDING_MAP } = WeldMapRules;

@classic
export default class IndexController extends Controller {
  @service
  userSession;

  @service('weldcloud-notes-data')
  notesData;

  @service('unit-system')
  units;

  @service
  weldnoteData;

  @alias('weldingMapController.isEditing')
  isEditing;

  sortPiecesBy = ['diameter', 'thickness'];

  sortPlatesBy = ['thickness'];

  @sort('model.pipingPieces', 'sortPiecesBy')
  sortedPieces;

  @sort('model.platePieces', 'sortPlatesBy')
  sortedPlates;

  @alias('weldnoteData.companyOptions')
  companyOptions;

  isShowingPipingPiecesTab = true;

  @tracked
  showPipingPiecesChooser = false;

  @tracked
  showPlatePiecesChooser = false;

  @not('isShowingPipingPiecesTab')
  isShowingPlatePiecesTab;

  get isMetric() {
    return this.units.isMetricSystem(this.model.unitSystem);
  }

  get isImperial() {
    return this.units.isImperialSystem(this.model.unitSystem);
  }

  get isEditing() {
    return this.userSession.canCreateQMatrix;
  }

  @action
  setCompany(company) {
    this.set(`model.${WELDING_MAP.COMPANY}`, company);
  }

  @action
  removePiece(piece) {
    this.get('model.pipingPieces').then((piecesCollection) => {
      if (piecesCollection.findBy('id', piece.get('id'))) {
        piecesCollection.removeObject(piece);
      }
    });
  }

  @action
  removePlate(plate) {
    this.get('model.platePieces').then((piecesCollection) => {
      if (piecesCollection.findBy('id', plate.get('id'))) {
        piecesCollection.removeObject(plate);
      }
    });
  }

  @action
  choosePipingPieceTab() {
    this.toggleProperty('isShowingPipingPiecesTab');
  }

  @action
  choosePlatePieceTab() {
    this.toggleProperty('isShowingPipingPiecesTab');
  }
}
