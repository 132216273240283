import classic from 'ember-classic-decorator';
import Base from 'ember-simple-auth/authenticators/base';
import { run } from '@ember/runloop';
import ENV from 'weldnote/config/environment';
import { service } from '@ember/service';

@classic
export default class SimplifiedMsalAuthenticator extends Base {
  @service
  session;

  @service
  ajax;

  @service
  userSession;

  timeoutId = -1;

  async authenticate(credentials) {
    return new Promise((resolve, reject) => {
      if (credentials && credentials.username && credentials.password) {
        this.authenticateWithCredentials(
          resolve,
          reject,
          credentials.username,
          credentials.password,
        );
      } else {
        run(null, reject, 'Email not provided');
      }
    });
  }

  restore(previousToken) {
    return new Promise((resolve, reject) => {
      try {
        const token = this.validateCurrentTokens(previousToken);
        this.scheduleExpiredTokenCheck();
        run(null, resolve, token);
      } catch (error) {
        run(null, reject, 'Invalid token, needs to login again');
      }
    });
  }

  invalidate() {
    return new Promise((resolve, reject) => {
      this.userSession.isLoggedInAsCustomerSupport = false;
      run(null, resolve, null);
    });
  }

  authenticateWithCredentials(resolve, reject, username, password) {
    let formData = new FormData();
    formData.append('client_id', ENV.msalWeldCloud.clientId);
    formData.append('scope', `${ENV.msalWeldCloud.scope} openid profile offline_access`);
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    this.ajax
      .post(`${ENV.msalWeldCloud.authority}/oauth2/v2.0/token`, {
        processData: false,
        contentType: false,
        data: formData,
      })
      .then((response) => {
        // We consider that the token expires after 12 hours, although the real expiration date
        // is available in the response token. This time interval can be configured in Azure's
        // User Flow. However, this implementation (the one used in WeldCloud) makes it easier
        // to handle token renewals, as it simplifies the process.
        let expiresOn = new Date();
        expiresOn.setHours(expiresOn.getHours() + 12);

        let parsedResponse = {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          expiresOn: expiresOn.getTime().toString(),
        };

        // Fetch the /me endpoint to check if we have a support session ongoing
        this.ajax
          .request(`${ENV.orgApiUrl}/org/odata/users/me`, {
            headers: {
              'Client-Application': 'weldcloud-notes',
              Authorization: `Bearer ${response.access_token}`,
              'ocp-apim-subscription-key': ENV.azureSubscriptionManagementId,
            },
          })
          .then((response) => {
            // If there's a support request token, use that, otherwise we use the original
            // access token
            if (response.supportRequestToken && response.supportRequestToken != '') {
              parsedResponse.accessToken = response.supportRequestToken;
            }

            this.scheduleExpiredTokenCheck();
            run(null, resolve, parsedResponse);
          });
      })
      .catch((reason) => {
        run(null, reject, reason);
      });
  }

  timeUntilTokenExpiration(tokenResponse) {
    if (tokenResponse && tokenResponse.accessToken && tokenResponse.expiresOn) {
      let now = new Date();
      let expires = new Date();
      expires.setTime(tokenResponse.expiresOn);
      let timeUntilExpiration = expires - now;
      return timeUntilExpiration;
    } else {
      return 0;
    }
  }

  hasTokenExpired(tokenResponse) {
    return this.timeUntilTokenExpiration(tokenResponse) <= 0;
  }

  scheduleExpiredTokenCheck() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      try {
        this.validateCurrentTokens();
        this.scheduleExpiredTokenCheck();
      } catch (error) {
        this.session.invalidate();
      }
    }, 30000);
  }

  validateCurrentTokens(previousToken) {
    // Fallback to local storage
    let currentToken = previousToken ?? this.session?.data?.authenticated;
    if (!currentToken || this.hasTokenExpired(currentToken)) {
      throw new Error('Invalid token');
    }
    return currentToken;
  }
}
