import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, computed } from '@ember/object';
import Constants from 'weldnote/utils/constants';
import { task } from 'ember-concurrency';

const { ARCHIVAL_STATE } = Constants;

@classic
export default class PqrsArchivedController extends Controller {
  @service
  intl;

  @service
  userSession;

  @service('weldnote-data')
  data;

  @alias('model.list')
  pqrs;

  @alias('model.count')
  pqrCount;

  page = 1;

  loadData = task(async (pageNumber = 1) => {
    let results = await this.data.listAllPQR(
      { page: pageNumber },
      {
        archivalState: ARCHIVAL_STATE.ARCHIVED,
      },
    );
    set(this, 'model', results);
  });

  @computed
  get gridColumns() {
    let { intl } = this;

    let certificateNumberLabel = intl.t('pqr-list.grid-columns.certificate-number');
    let weldTypeLabel = intl.t('pqr-list.grid-columns.weld-type');
    let weldingProcessesLabel = intl.t('pqr-list.grid-columns.welding-processes');
    let diameterLabel = intl.t('pqr-list.grid-columns.diameter', {
      unit: this.userSession.distanceUnit,
    });
    let baseMaterialThicknessLabel = intl.t('pqr-list.grid-columns.base-material-thickness', {
      unit: this.userSession.distanceUnit,
    });
    let baseMaterialsLabel = intl.t('pqr-list.grid-columns.base-materials');
    let heatInputLabel = intl.t('pqr-list.grid-columns.heat-input', {
      unit: this.userSession.heatDistanceUnit,
    });
    let postWeldHeatTreamentLabel = intl.t('pqr-list.grid-columns.post-weld-heat-treatment', {
      unit: this.userSession.temperatureUnit,
    });
    let preHeatLabel = intl.t('pqr-list.grid-columns.pre-heat', {
      unit: this.userSession.temperatureUnit,
    });

    return [
      {
        width: '40px',
        sortable: false,
        cellComponent: 'weldnote-grid/row-toggle',
        breakpoints: ['mobile', 'tablet', 'laptop'],
      },
      {
        valuePath: 'certificateNumber',
        label: certificateNumberLabel,
        width: '200px',
        cellComponent: 'weldnote-grid/pqr-link-archive',
        sortable: false,
      },
      {
        valuePath: 'weldType',
        label: weldTypeLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/weld-type',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'weldingProcesses',
        label: weldingProcessesLabel,
        sortable: false,
        cellComponent: 'weldnote-grid/welding-processes',
        breakpoints: ['tablet', 'laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterials',
        label: baseMaterialsLabel,
        cellComponent: 'weldnote-grid/base-material-for-list',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'baseMaterialThickness',
        label: baseMaterialThicknessLabel,
        align: 'right',
        cellComponent: 'weldnote-grid/base-material-thickness-for-list',
        sortable: false,
        breakpoints: ['laptop', 'desktop'],
      },
      {
        valuePath: 'diameter',
        align: 'right',
        cellComponent: 'weldnote-grid/pqr-diameter',
        label: diameterLabel,
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'heatInput',
        align: 'right',
        label: heatInputLabel,
        cellComponent: 'weldnote-grid/pqr-heat-input',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'preHeat',
        align: 'right',
        label: preHeatLabel,
        cellComponent: 'weldnote-grid/pqr-preheat',
        sortable: false,
        breakpoints: ['desktop'],
      },
      {
        valuePath: 'postWeldHeatTreament',
        align: 'right',
        label: postWeldHeatTreamentLabel,
        cellComponent: 'weldnote-grid/pqr-pwht',
        sortable: false,
        breakpoints: ['desktop'],
      },
    ];
  }
}
