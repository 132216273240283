import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'weldnote/config/environment';
import { set } from '@ember/object';

@classic
export default class LoginRoute extends Route {
  @service
  session;

  @service
  router;

  @service
  ajax;

  @service
  orgApi;

  @service
  sharedStorage;

  displayLoginFallbackPage = false;

  get isLocalEnvironment() {
    return location.hostname === 'localhost';
  }

  get isSafari() {
    const userAgentString = navigator.userAgent;
    const chromeAgent = userAgentString.includes('Chrome');
    const safariAgent = userAgentString.includes('Safari');
    // Since chrome user agent also contains "Safari", we must check if it's not chrome
    return safariAgent && !chromeAgent;
  }

  model() {
    return {};
  }

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      // We return here to display the fallback login page.
      // This is necessary for localhost (where cross-storage doesn't work)
      // and Safari (which blocks third-party cookies by default, assuming default settings).
      if (this.isSafari || this.isLocalEnvironment) {
        this.displayLoginFallbackPage = true;
        return;
      }

      try {
        await this.session.authenticate('authenticator:cross-storage');
        await this.orgApi.syncAccount();
      } catch (e) {
        window.location.href = `${ENV.esabCloudLink}/login?p=/weldnotes`;
      }
    } else {
      this.router.transitionTo('/');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    set(controller, 'displayLoginFallbackPage', this.displayLoginFallbackPage);
  }
}
