import classic from 'ember-classic-decorator';
import Base from 'ember-simple-auth/authenticators/base';
import { run } from '@ember/runloop';
import ENV from 'weldnote/config/environment';
import { service } from '@ember/service';

@classic
export default class CustomAuthenticator extends Base {
  @service
  session;

  @service
  ajax;

  @service
  sharedStorage;

  @service
  userSession;

  timeoutId = -1;

  async authenticate(options) {
    return new Promise((resolve, reject) => {
      this.trySsoAuthentication(resolve, reject);
    });
  }

  restore(previousToken) {
    return new Promise((resolve, reject) => {
      this.validateCurrentTokens(previousToken)
        .then((token) => {
          this.scheduleExpiredTokenCheck();
          run(null, resolve, token);
        })
        .catch(() => {
          run(null, reject, 'Invalid token, needs to login again');
        });
    });
  }

  invalidate() {
    window.location.href = `${ENV.esabCloudLink}/logout`;
  }

  trySsoAuthentication(resolve, reject) {
    this.validateCurrentTokens()
      .then((token) => {
        this.scheduleExpiredTokenCheck();
        run(null, resolve, token);
      })
      .catch(() => {
        run(null, reject, 'Invalid token, needs to login again');
      });
  }

  timeUntilTokenExpiration(tokenResponse) {
    if (tokenResponse && tokenResponse.accessToken && tokenResponse.expiresOn) {
      let now = new Date();
      let expires = new Date();
      expires.setTime(tokenResponse.expiresOn);
      let timeUntilExpiration = expires - now;
      return timeUntilExpiration;
    } else {
      return 0;
    }
  }

  hasTokenExpired(tokenResponse) {
    return this.timeUntilTokenExpiration(tokenResponse) <= 0;
  }

  scheduleExpiredTokenCheck() {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      this.validateCurrentTokens()
        .then(() => {
          this.scheduleExpiredTokenCheck();
        })
        .catch(() => {
          this.session.invalidate();
        });
    }, 30000);
  }

  validateCurrentTokens(previousToken) {
    return new Promise((resolve, reject) => {
      const handleLocalFallback = (e) => {
        // Fallback to local storage
        let currentToken = previousToken ?? this.session?.data?.authenticated;
        if (!this.hasTokenExpired(currentToken)) {
          run(null, resolve, currentToken);
        } else {
          run(null, reject, 'Invalid token');
        }
      };

      this.sharedStorage
        .getValue('msal.supportToken')
        .then((supportTokenStr) => {
          let supportToken = JSON.parse(supportTokenStr);
          if (!this.hasTokenExpired(supportToken)) {
            this.userSession.isLoggedInAsCustomerSupport = true;
            run(null, resolve, supportToken);
          } else {
            // If supportToken is expired, try msal.token
            this.sharedStorage
              .getValue('msal.token')
              .then((sharedTokenStr) => {
                let sharedToken = JSON.parse(sharedTokenStr);
                if (!this.hasTokenExpired(sharedToken)) {
                  run(null, resolve, sharedToken);
                } else {
                  run(null, reject, 'Invalid token');
                }
              })
              .catch(handleLocalFallback);
          }
        })
        .catch(handleLocalFallback);
    });
  }
}
