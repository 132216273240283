import Service, { service } from '@ember/service';
import { IntlService } from 'ember-intl';
import Constants from 'weldnote/utils/constants';


const {
  UNITS,
  UNIT_SYSTEM
} = Constants;

export default class UnitSystemService extends Service {

  @service
  declare intl: IntlService;

  isMetricSystem(unitSystem: string) {
    if (unitSystem == UNIT_SYSTEM.METRIC) {
      return true;
    }
    return false;
  }

  isImperialSystem(unitSystem: string) {
    if (unitSystem == UNIT_SYSTEM.IMPERIAL) {
      return true;
    }
    return false;
  }

  getTemperatureUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return UNITS.CELSIUS;
    } else if (this.isImperialSystem(unitSystem)) {
      return UNITS.FAHRENHEIT;
    }
    return '';
  }

  getDistanceUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.MILLIMETER}`);
    } else if (this.isImperialSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.INCH}`);
    }
    return '';
  }

  getFlowUnit(unitSystem: string): string {
    if (this.isMetricSystem(unitSystem)) {
      return UNITS.LITER_MINUTE;
    } else if (this.isImperialSystem(unitSystem)) {
      return UNITS.CUBIC_FEET_HOUR;
    }
    return '';
  }

  getHeatDistanceUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.KILO_JOULE_MILLIMETER}`);
    } else if (this.isImperialSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.KILO_JOULE_INCH}`);
    }
    return '';
  }

  getWireSpeedUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.METER_MINUTE}`);
    } else if (this.isImperialSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.INCH_MINUTE}`);
    }
    return '';
  }

  getTravelSpeedUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.MILLIMETER_MINUTE}`);
    } else if (this.isImperialSystem(unitSystem)) {
      return this.intl.t(`lov.units.${UNITS.INCH_MINUTE}`);
    }
    return '';
  }

  getForceUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return UNITS.NEWTON;
    } else if (this.isImperialSystem(unitSystem)) {
      return UNITS.POUNDS;
    }
    return '';
  }

  getAreaUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return UNITS.SQUARE_MILLIMETER;
    } else if (this.isImperialSystem(unitSystem)) {
      return UNITS.SQUARE_INCH;
    }
    return '';
  }

  getPressureUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return UNITS.NEWTON_SQUARE_MILIMETER;
    } else if (this.isImperialSystem(unitSystem)) {
      return UNITS.PSI;
    }
    return '';
  }

  getEnergyUnit(unitSystem: string) {
    if (this.isMetricSystem(unitSystem)) {
      return UNITS.JOULE;
    } else if (this.isImperialSystem(unitSystem)) {
      return UNITS.FEET_PER_POUND;
    }
    return '';
  }

}
