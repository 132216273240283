import classic from 'ember-classic-decorator';
import { gt, notEmpty, alias } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import { set, computed } from '@ember/object';
import EVENTS from 'weldnote/utils/analytics-events';

@classic
export default class InstanceTrackerService extends Service {
  @service
  store;

  @service
  analytics;

  @service
  userSession;

  weldingMap = null;

  weldingBook = null;

  @alias('weldingMap')
  currentWeldingMap;

  @alias('weldingBook')
  currentWeldingBook;

  getWeldingMap() {
    if (this.hasWeldingMap) {
      return this.weldingMap;
    }
    let weldingMap = this.store.createRecord('welding-map', {
      unitSystem: this.userSession.unitSystem,
    });
    set(this, 'weldingMap', weldingMap);
    return weldingMap;
  }

  getWeldingBook() {
    if (this.hasWeldingBook) {
      return this.weldingBook;
    }
    let weldingBook = this.store.createRecord('welding-book', {
      unitSystem: this.userSession.unitSystem,
    });
    set(this, 'weldingBook', weldingBook);
    return weldingBook;
  }

  @notEmpty('weldingMap')
  hasWeldingMap;

  @notEmpty('weldingBook')
  hasWeldingBook;

  @gt('count', 0)
  hasUnsavedElements;

  @computed('hasWeldingMap', 'hasWeldingBook')
  get count() {
    let count = 0;
    if (this.hasCertificate) {
      count++;
    }
    if (this.hasWeldingMap) {
      count++;
    }
    if (this.hasWeldingBook) {
      count++;
    }
    return count;
  }

  savedWeldingMap() {
    set(this, 'weldingMap', null);
  }

  savedWeldingBook() {
    set(this, 'weldingBook', null);
  }

  discardAll() {
    set(this, 'weldingMap', null);
    set(this, 'weldingBook', null);
  }

  discardWeldingMap() {
    let { weldingMap } = this;
    this.store.unloadRecord(weldingMap);
    set(this, 'weldingMap', null);
    this.analytics.trackEvent(EVENTS.DISCARD, {
      type: 'welding-map',
    });
  }

  discardWeldingBook() {
    let { weldingBook } = this;
    this.store.unloadRecord(weldingBook);
    set(this, 'weldingBook', null);
    this.analytics.trackEvent(EVENTS.DISCARD, {
      type: 'weldingBook',
    });
  }
}
