import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';

export default class NewRoute extends Route {
  @service
  store;

  @service
  userSession;

  @service session;

  @service
  router;

  modelName = 'welding-procedure-specification';

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (!this.userSession.canCreateWPS) {
      this.router.transitionTo('welding-procedure-specifications');
    }
  }

  model() {
    return this.store.createRecord('welding-procedure-specification', {
      unitSystem: this.userSession.unitSystem,
    });
  }
}
