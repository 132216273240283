import { service } from '@ember/service';
import { attr, belongsTo } from '@ember-data/model';
import { get, computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import BaseModel from 'weldnote/models/base-model';
import Constants from 'weldnote/utils/constants';
import {
  fromMilimeterSecondToMeterMinute,
  fromMilimeterSecondToMilimeterMinute,
} from 'weldnote/utils/unit-system/speed';
import TypeCurrentPolarity from './type-current-polarity';
import ArcTransferMode from './arc-transfer-mode';
import PQRModel from './pqr';
import UserSessionService from 'weldnote/services/user-session';
import UnitSystemService from 'weldnote/services/unit-system';

const { WELDING_LAYER_OPTIONS } = Constants;

export default class PqrWeldingParameter extends BaseModel {
  @service()
  declare userSession: UserSessionService;

  @service('unit-system')
  declare units: UnitSystemService;

  @attr('number')
  declare orderNum?: number;

  @attr('string')
  declare passNumber?: string;

  @attr()
  declare fillerMaterialDiameters?: string;

  @attr('number')
  declare currentMinimum?: number;

  @attr('number')
  declare currentMaximum?: number;

  @attr('number')
  declare voltageMinimum?: number;

  @attr('number')
  declare voltageMaximum?: number;

  @belongsTo('type-current-polarity', { async: false })
  declare typeCurrent: TypeCurrentPolarity;

  @attr('decimal-value')
  declare wireFeedSpeedMinimum?: number;

  @attr('decimal-value')
  declare wireFeedSpeedMaximum?: number;

  @attr('decimal-value')
  declare travelSpeedMinimum?: number;

  @attr('decimal-value')
  declare travelSpeedMaximum?: number;

  @attr('decimal-value')
  declare heatInputMinimum?: number;

  @attr('decimal-value')
  declare heatInputMaximum?: number;

  @belongsTo('arc-transfer-mode', { async: false })
  declare arcTransferMode: ArcTransferMode;

  @belongsTo('pqr', { async: false })
  declare pqr: PQRModel;

  @attr('string')
  declare layer?: string;

  @attr({
    defaultValue() {
      return {};
    },
  })
  declare additionalData?: string;

  get travelSpeedUnit(): string {
    return this.units.getTravelSpeedUnit(this.pqr.unitSystem || '');
  }

  get wireSpeedUnit(): string {
    return this.units.getWireSpeedUnit(this.pqr.unitSystem || '');
  }

  get isMetricSystem(): boolean {
    return this.units.isMetricSystem(this.pqr.unitSystem || this.userSession.unitSystem || '');
  }

  get isImperialSystem(): boolean {
    return this.units.isImperialSystem(this.pqr.unitSystem || this.userSession.unitSystem || '');
  }

  metadata = {
    modelName: 'pqr-welding-parameter',
    passNumber: { required: true },
    currentMinimum: { required: true },
    currentMaximum: { required: true },
    voltageMinimum: { required: true },
    voltageMaximum: { required: true },
    typeCurrent: { required: true },
    layer: { required: true },
  };

  validations = {
    passNumber: {
      presence: {
        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      length: [0, 10],
    },

    currentMinimum: {
      presence: {
        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMinimum('currentMaximum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.getBelowMaximumMessage(get(model, 'currentMaximum'), '(A)');
        },
      },
    },

    currentMaximum: {
      presence: {
        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMaximum('currentMinimum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.getAboveMinimumMessage(get(model, 'currentMinimum'), '(A)');
        },
      },
    },

    voltageMinimum: {
      presence: {
        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMinimum('voltageMaximum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.getBelowMaximumMessage(get(model, 'voltageMaximum'), '(V)');
        },
      },
    },

    voltageMaximum: {
      presence: {
        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },

      numericality: {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 9999,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMaximum('voltageMinimum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.getAboveMinimumMessage(get(model, 'voltageMinimum'), '(V)');
        },
      },
    },

    typeCurrent: {
      custom: {
        validation(_key: string, value: any) {
          return !isEmpty(value) && !isEmpty(get(value, 'id'));
        },

        message(_key: string, _value: string, model: BaseModel): string {
          return model.translate('generic.error.input-value');
        },
      },
    },

    wireFeedSpeedMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMinimum('wireFeedSpeedMaximum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          if (get(model, 'isMetricSystem')) {
            return model.getBelowMaximumMessage(
              fromMilimeterSecondToMeterMinute(get(model, 'wireFeedSpeedMaximum')),
              `(${get(model, 'wireSpeedUnit')})`
            );
          } else if (get(model, 'isImperialSystem')) {
            return model.getBelowMaximumMessage(
              get(model, 'wireFeedSpeedMaximum'),
              `(${get(model, 'wireSpeedUnit')})`
            );
          }
          return '';
        },
      },
    },

    wireFeedSpeedMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMaximum('wireFeedSpeedMinimum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          if (get(model, 'isMetricSystem')) {
            return model.getAboveMinimumMessage(
              fromMilimeterSecondToMeterMinute(get(model, 'wireFeedSpeedMinimum')),
              `(${get(model, 'wireSpeedUnit')})`
            );
          } else if (get(model, 'isImperialSystem')) {
            return model.getAboveMinimumMessage(
              get(model, 'wireFeedSpeedMinimum'),
              `(${get(model, 'wireSpeedUnit')})`
            );
          }
          return '';
        },
      },
    },

    heatInputMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMinimum('heatInputMaximum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.getBelowMaximumMessage(get(model, 'heatInputMaximum'), '(kJ/mm)');
        },
      },
    },

    heatInputMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMaximum('heatInputMinimum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.getAboveMinimumMessage(get(model, 'heatInputMinimum'), '(kJ/mm)');
        },
      },
    },

    travelSpeedMinimum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMinimum('travelSpeedMaximum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          if (get(model, 'isMetricSystem')) {
            return model.getBelowMaximumMessage(
              fromMilimeterSecondToMilimeterMinute(get(model, 'travelSpeedMaximum')),
              `(${get(model, 'travelSpeedUnit')})`
            );
          } else if (get(model, 'isImperialSystem')) {
            return model.getBelowMaximumMessage(
              get(model, 'travelSpeedMaximum'),
              `(${get(model, 'travelSpeedUnit')})`
            );
          }
          return '';
        },
      },
    },

    travelSpeedMaximum: {
      numericality: {
        allowBlank: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 99999.99,
      },

      custom: {
        if(_key: string, value: any): boolean {
          return !isEmpty(value);
        },

        validation(_key: string, value: any, model: PqrWeldingParameter): boolean {
          return model.validateMaximum('travelSpeedMinimum', value);
        },

        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          if (get(model, 'isMetricSystem')) {
            return model.getAboveMinimumMessage(
              fromMilimeterSecondToMilimeterMinute(get(model, 'travelSpeedMinimum')),
              `(${get(model, 'travelSpeedUnit')})`
            );
          } else if (get(model, 'isImperialSystem')) {
            return model.getAboveMinimumMessage(
              get(model, 'travelSpeedMinimum'),
              `(${get(model, 'travelSpeedUnit')})`
            );
          }
          return '';
        },
      },
    },

    layer: {
      presence: {
        message(_key: string, _value: any, model: PqrWeldingParameter): string {
          return model.translate('generic.error.input-value');
        },
      },
    },
  };

  validateMinimum(propertyMaximumValue: any, value: any) {
    let maximum = parseFloat(get(this, propertyMaximumValue));
    if (!isEmpty(maximum) && !isNaN(maximum)) {
      let minimum = parseFloat(value);
      return minimum <= maximum;
    }
    return true;
  }

  validateMaximum(propertyMinimumValue: any, value: any) {
    let minimum = parseFloat(get(this, propertyMinimumValue));
    if (!isEmpty(minimum) && !isNaN(minimum)) {
      let maximum = parseFloat(value);
      return maximum >= minimum;
    }
    return true;
  }

  getBelowMaximumMessage(approvalRange: string | number | undefined, unit = '') {
    if (!approvalRange) {
      return '';
    }
    return this.translate('model-validations.pqr-parameter.must-be-below-maximum', {
      approvalRange,
      unit,
    });
  }

  getAboveMinimumMessage(approvalRange: string | number | undefined, unit: string) {
    if (!approvalRange) {
      return '';
    }
    return this.translate('model-validations.pqr-parameter.must-be-above-minimum', {
      approvalRange,
      unit,
    });
  }

  @computed(
    'layer',
    'pqr.{fillerMaterialRootTestPiece,fillerMaterialFillTestPiece,fillerMaterialCapTestPiece}'
  )
  get fillerMaterialType() {
    if (this.layer === WELDING_LAYER_OPTIONS.ROOT) {
      return this.pqr.fillerMaterialRootTestPiece;
    } else if (this.layer === WELDING_LAYER_OPTIONS.FILL) {
      return this.pqr.fillerMaterialFillTestPiece;
    } else if (this.layer === WELDING_LAYER_OPTIONS.CAP) {
      return this.pqr.fillerMaterialCapTestPiece;
    }
    return null;
  }

  @computed(
    'layer',
    'pqr.{weldingProcessRootTestPiece,weldingProcessFillTestPiece,weldingProcessCapTestPiece}'
  )
  get weldingProcess() {
    if (this.pqr == null) {
      // This should not really happen here? what gives
      return null;
    }
    if (this.layer === WELDING_LAYER_OPTIONS.ROOT) {
      return get(this.pqr, 'weldingProcessRootTestPiece');
    } else if (this.layer === WELDING_LAYER_OPTIONS.FILL) {
      return get(this.pqr, 'weldingProcessFillTestPiece');
    } else if (this.layer === WELDING_LAYER_OPTIONS.CAP) {
      return get(this.pqr, 'weldingProcessCapTestPiece');
    }
    return null;
  }
}
