import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import ListRoute from 'weldnote/routes/_base/list';
import { set } from '@ember/object';

@classic
export default class WelderCertificatesRoute extends ListRoute {
  @service('weldnote-data')
  data;

  @service('helpdesk-widget')
  helpdesk;

  @service
  weldcloudNotesData;

  @service('weldcloud-notes-data')
  notesData;

  model() {
    return this.data.listAllWelderCertificates();
  }

  setupController(controller, model) {
    controller.set('model', model.list);
    controller.set('totalCount', model.count);
    set(
      controller,
      'selectedUnitSystem',
      this.notesData.getUnitSystem(this.userSession.unitSystem)
    );
  }

  activate() {
    this.helpdesk.setSearchItems('WPQ');
  }
}
