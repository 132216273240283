import classic from 'ember-classic-decorator';
import { service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'weldnote/config/environment';

@classic
export default class LogoutRoute extends Route {
  @service
  session;

  activate() {
    this.session.invalidate();
  }
}
