import { service } from '@ember/service';
import Route from '@ember/routing/route';

/**
 * @public
 * The default route for a list view. Only prevents unauthorized access
 */
export default class ListRoute extends Route {
  @service
  userSession;

  @service session;

  @service
  router;

  beforeModel(transition) {
    super.beforeModel(...arguments);
    this.session.requireAuthentication(transition, 'login');
    if (this.userSession.isAccountLocked) {
      this.router.transitionTo('index');
    }
  }
}
